<template>
    <BaseModal
        class="create-label-modal-container"
        :style="{ width: '25rem' }"
        header="Create New Label"
        :loading="saving"
        :showActions="true"
        @close="close"
        @handleAction="save"
    >
        <div class="container modal-content">
            <TextInput placeholder="Label Name" v-model="labelText" />
        </div>
    </BaseModal>
</template>

<script>
import { BaseModal } from '@edsights/ui-core';
import TextInput from '@/components-deprecated/global/TextInput';
import { createChatbotFlowLabel } from '@/api/chatbot-flow-labels';

export default {
    name: 'CreateChatbotFlowLabelModal',
    components: {
        BaseModal,
        TextInput
    },
    data() {
        return {
            labelText: '',
            saving: false
        };
    },
    methods: {
        close() {
            this.$emit('close-modal');
        },
        async save() {
            if (!this.labelText.trim()) {
                this.$Alert.alert({
                    type: 'error',
                    message: 'Label name is required.',
                    timeout: 5000
                });
                return;
            }

            try {
                this.saving = true;
                const createdLabel = await createChatbotFlowLabel(this.labelText);
                this.saving = false;

                if (
                    createdLabel &&
                    typeof createdLabel.text === 'string' &&
                    typeof createdLabel.id === 'string'
                ) {
                    const { text, id } = createdLabel;
                    this.$emit('label-created', { text, id });
                }
                this.close();
            } catch (error) {
                this.saving = false;
                this.$Alert.alert({
                    type: 'error',
                    message: 'There was an issue while creating the label. Please try again.',
                    timeout: 5000
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import './ChatbotFlowList/components/input-style-mixin.scss';

.create-label-modal-container {
    .modal-content {
        display: flex;
        align-items: center;
        justify-content: center;

        :deep(input) {
            @include input-style();
        }
    }
}
</style>
