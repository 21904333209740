<template>
    <div v-if="editable" @click="toggleStatus" title="Click to edit status">
        <StatusLabel :status="props.status" :editable="editable" />
    </div>
    <StatusLabel v-else :status="props.status" />
</template>

<script setup>
import { computed } from 'vue';
import { CHATBOT_FLOW_STATUS } from '@/consts/chatbot-flow';

import StatusLabel from './StatusLabel.vue';

const props = defineProps({
    status: {
        type: String,
        required: true
    }
});

const emit = defineEmits(['toggle-status']);

const editable = computed(() => props.status === CHATBOT_FLOW_STATUS.DRAFT.value);

const toggleStatus = () => {
    emit('toggle-status', props.status);
};
</script>
