<template>
    <BaseModal
        class="templates-label-modal-container"
        header="Assign Label to Template"
        :style="{ width: '50rem', height: '40rem' }"
        :contentStyle="{ height: '100%' }"
        :disableAction="disableSave"
        :loading="saving || creating"
        :showActions="true"
        @close="onCancel"
        @handleAction="onSave"
    >
        <div class="container modal-content">
            <LoadingSpinner v-if="loading" />
            <div v-else-if="createMode">
                <TextInput placeholder="Create new label..." v-model="createValue" />
                <span v-if="labelAlreadyExists">Label already exists.</span>
            </div>
            <TextInputWithSearch
                placeholder="Search for a label..."
                :options="labelOptions"
                v-model="searchValue"
                @option-selected="onOptionSelected"
            />
        </div>
    </BaseModal>
</template>

<script>
import { BaseModal } from '@edsights/ui-core';
import TextInputWithSearch from '@/components-deprecated/global/TextInputWithSearch';
import TextInput from '@/components-deprecated/global/TextInput';
import { createChatbotFlowLabel, getChatbotFlowLabels } from '@/api/chatbot-flow-labels';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';

export default {
    name: 'TemplatesLabelModal',
    components: { LoadingSpinner, TextInputWithSearch, BaseModal, TextInput },
    props: {
        saving: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            labelOptions: [],
            searchValue: '',
            selectedLabelOption: null,
            createMode: false,
            createValue: '',
            creating: false,
            loading: false
        };
    },
    async created() {
        this.loading = true;
        const labels = await getChatbotFlowLabels();
        if (Array.isArray(labels)) {
            this.labelOptions = [
                { label: 'Create new label...', value: 'create-new' },
                ...labels.map(l => ({ value: l.id, label: l.text }))
            ];
            this.loading = false;
        }
    },
    methods: {
        onOptionSelected(option) {
            this.selectedLabelOption = option;
            if (option && typeof option === 'object' && option.value === 'create-new') {
                this.createMode = true;
                this.searchValue = '';
            }
        },
        async onSave() {
            if (
                this.createMode &&
                typeof this.createValue === 'string' &&
                this.createValue.length
            ) {
                this.creating = true;
                const createdLabel = await createChatbotFlowLabel(this.createValue);
                if (typeof createdLabel === 'object' && typeof createdLabel.id === 'string') {
                    this.$emit('onSave', createdLabel.id);
                }
                this.creating = false;
                return;
            }

            if (
                typeof this.selectedLabelOption === 'object' &&
                typeof this.selectedLabelOption.value === 'string'
            ) {
                this.$emit('onSave', this.selectedLabelOption.value);
            }
        },
        onCancel() {
            if (this.createMode) {
                this.createMode = false;
                this.createValue = '';
                return;
            }

            this.$emit('onClose');
        }
    },
    computed: {
        labelAlreadyExists() {
            if (
                this.createMode &&
                typeof this.createValue === 'string' &&
                Array.isArray(this.labelOptions) &&
                this.labelOptions.find(o => o.label === this.createValue)
            ) {
                return true;
            }
            return false;
        },
        disableSave() {
            if (this.createMode) {
                return this.labelAlreadyExists || !this.createValue;
            }

            return !this.selectedLabelOption;
        }
    }
};
</script>

<style lang="scss" scoped>
@import 'input-style-mixin.scss';

.templates-label-modal-container {
    .modal-content {
        :deep(input) {
            @include input-style();
        }
        :deep(.menu-container) {
            width: 100%;
        }
    }
}
</style>
