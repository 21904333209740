<template>
    <div :class="['status-content', { editable }]">
        <div class="status-indicator" :class="[CHATBOT_FLOW_STATUS[props.status].value]" />
        {{ CHATBOT_FLOW_STATUS[props.status].label }}
    </div>
</template>

<script setup>
import { CHATBOT_FLOW_STATUS } from '@/consts/chatbot-flow';

const props = defineProps({
    status: {
        type: String,
        required: true
    },
    editable: {
        type: Boolean,
        default: false
    }
});
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@/views/staff/ChatbotFlowList/components/FlowsV2/styles/mixins';

.status-content {
    display: flex;
    align-items: center;

    &.editable {
        @include editable-indicator;
        cursor: pointer;
    }

    .status-indicator {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin-right: 5px;

        &.SCHEDULED {
            background-color: $green;
        }

        &.DRAFT {
            background-color: $red;
        }

        &.INITIATED {
            background-color: $edsights-blue;
        }
    }
}
</style>
