<template>
    <BaseCard>
        <template #content>
            <div class="card-header">
                <h2>{{ headerDisplay }}</h2>
            </div>
            <EmptyState
                v-if="hasError"
                icon="#icon-svs-chart"
                message="Nothing to see here yet"
                sub-message="Stay tuned - more SVS insights are coming soon."
            />
            <div v-else class="card-content">
                <StudentGauge
                    :totalFeedback="feedback.feedbackCount"
                    :totalStudents="studentCount"
                    :studentType="name"
                    :color="color"
                    :emptyColor="emptyColor"
                />
                <BaseDivider />
                <div
                    v-for="([categoryName, report], index) in Object.entries(studentFeedback)"
                    :key="categoryName"
                    class="feedback-container"
                >
                    <div class="feedback-summary-row">
                        <div class="feedback-summary">
                            <b> {{ categoryName }} </b>:&nbsp;<span>{{ report.summary }}</span>
                        </div>

                        <a
                            class="feedback-history-icon-button"
                            @click="modalMessages[categoryName] = true"
                        >
                            <BaseIcon
                                class="icon"
                                icon="comment"
                                color="#056DC7"
                            />
                            Samples
                        </a>
                    </div>

                    <BaseModal
                        v-if="modalMessages[categoryName]"
                        @close="modalMessages[categoryName] = false"
                        class="container-modal"
                    >
                        <template #header>
                            <div class="quotes-header">{{ categoryName }}</div>
                        </template>
                        <div class="quotes-summary">{{ report.summary }}</div>
                        <div class="messages-info">Representative Comment Samples</div>
                        <div class="messages-info-subtext">{{ toolTipText }}</div>
                        <div class="quotes-list">
                            <div class="quote" v-for="message in messages(report)" :key="message">
                                <p>"{{ message }}"</p>
                            </div>
                        </div>

                        <template #footer>
                            <ReportRequestDownloadButton
                                class="report-download-button"
                                reportType="CHATBOT_CONVERSATIONS"
                                ariaLabel="Download Full Report"
                                buttonText="Download Full Report"
                                :reportOptions="{
                                    chatbotFlowId,
                                    cleanOutput: true
                                }"
                                @openModalReportDownload="modalMessages[categoryName] = false"
                            />
                        </template>

                    </BaseModal>

                    <BaseDivider v-if="index < studentFeedbackSize" />
                </div>
            </div>
        </template>
    </BaseCard>
</template>

<script>
import { BaseDivider, BaseCard, BaseModal, BaseIcon } from '@edsights/ui-core';
import EmptyState from '@/components-deprecated/global/EmptyState';
import StudentGauge from './StudentGauge.vue';
import ReportRequestDownloadButton from '@/components-deprecated/ReportRequestDownloadButton.vue';

export default {
    name: 'StudentFeedback',
    components: {
        ReportRequestDownloadButton,
        StudentGauge,
        BaseDivider,
        BaseCard,
        BaseModal,
        BaseIcon,
        EmptyState
    },
    props: {
        studentType: {
            type: String,
            required: true
        },
        feedback: {
            type: Object,
            required: true
        },
        studentCount: {
            type: Number,
            required: true
        },
        chatbotFlowId: {
            type: String,
            required: false
        }
    },
    computed: {
        hasError() {
            return this.studentFeedback.error;
        },
        headerDisplay() {
            return this.studentTypes[this.studentType]?.displayName;
        },
        color() {
            return this.studentTypes[this.studentType]?.color || 'black';
        },
        emptyColor() {
            return this.studentTypes[this.studentType]?.emptyColor || 'black';
        },
        name() {
            return this.studentTypes[this.studentType]?.name;
        },
        studentFeedback() {
            return this.feedback.summary;
        },
        studentFeedbackSize() {
            return Object.keys(this.studentFeedback).length - 1;
        }
     },
    methods: {
        messages(report) {
            return report?.messages.map(msg => msg.moderatedMessage);
        }
    },
    data() {
        return {
            toolTipText:
                'The following comments are samples of feedback with occasional light edits for clarity and appropriateness while keeping the original intent intact.',
            modalMessages: {},
            studentTypes: {
                PROMOTER: {
                    displayName: '🌟 Promoter Themes',
                    name: 'promoters',
                    emptyColor: '#CCE6D2',
                    color: '#339D4D'
                },
                PASSIVE: {
                    displayName: '🤔 Passive Themes',
                    name: 'passives',
                    emptyColor: '#F8E9BF',
                    color: '#E6A700'
                },
                DETRACTOR: {
                    displayName: '⚠️ Detractor Themes',
                    name: 'detractors',
                    emptyColor: '#F5C9D2',
                    color: '#D9274B'
                }
            }
        };
    }
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';

.feedback-summary {
    width: 85%;
}

.feedback-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feedback-summary-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
}

.feedback-history-icon-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-dark;
    background-color: rgba($primary-dark, 0.1);
    font-size: 0.9rem;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    font-weight: bold;
    gap: 0.25rem;
    cursor: pointer;
}

.container-modal {
    font-size: 1.25rem;
}

.messages-info {
    display: flex;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 30px 0px 0px 0px;
}

.card-header {
    padding: 1rem;
    flex-shrink: 0;
}

.card-header h2 {
    font-size: 1.25rem;
    color: #333;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.card-content {
    padding: 0 1rem;
    flex: 1;
    overflow: auto;
}

.quote {
    margin: 1rem 0;
    padding: 1.5rem;
    background: #f9f9f9;
    border-left: 5px solid #3498db;
    border-radius: 4px;
    position: relative;
}

.quote::before {
    font-size: 3rem;
    color: #3498db;
    position: absolute;
    top: -20px;
    left: 10px;
}

.quote p {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #555;
}

.quotes-header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.quotes-summary {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}
.helper-icon {
    padding-left: 0.5rem;
}
.report-download-button {
    margin: 0.5rem 0;
}
</style>
