<template>
    <div class="flow-date-editor" @click.stop>
        <div v-if="showDateEditor" class="date-editor">
            <BaseDatePicker
                v-model="inputDate"
                :min-date="minDate"
                show-time
                placeholder="Select date and time"
            />
            <div class="actions">
                <BaseButton
                    :disabled="saving"
                    :style="`secondary`"
                    label="Cancel"
                    @click="toggleDateEditor(false)"
                />
                <BaseButton
                    label="Save"
                    :loading="saving"
                    :disabled="disableSave"
                    @click="onSaveClick"
                />
            </div>
        </div>
        <div v-else-if="flowInitiated" class="date readonly">
            {{ getLongDate }}
        </div>
        <div
            v-else
            :class="['date', { editable: flowStatus !== CHATBOT_FLOW_STATUS.INITIATED.value }]"
            @click="toggleDateEditor(true)"
            title="Click to edit scheduled date"
        >
            {{ getLongDate }}
        </div>
        <BaseModal
            v-if="showConfirmationModal"
            header="Attention: You are editing a scheduled flow."
            :style="{ width: '40rem' }"
            :showActions="true"
            @close="toggleConfirmationModal(false)"
            @handleAction="onConfirm"
        >
            <div class="confirmation-modal-content">
                <label>You are changing the date to:</label>
                <div class="date">{{ getInputLongDate }}</div>
            </div>
        </BaseModal>
    </div>
</template>

<script setup>
// Vue
import { toRef } from 'vue';

// UI Components
import { BaseDatePicker, BaseModal, BaseButton } from '@edsights/ui-core';

// Composables
import { useFlowDate } from '@/views/staff/ChatbotFlowList/components/FlowsV2/composables/useFlowDate';

// Constants
import { CHATBOT_FLOW_STATUS } from '@/consts/chatbot-flow';

// Props
const props = defineProps({
    date: {
        type: String,
        required: true
    },
    flowId: {
        type: String,
        required: true
    },
    flowStatus: {
        type: String,
        required: true
    }
});

// vars
const minDate = new Date();

// Emits
const emit = defineEmits(['flow-date-updated']);

// Composables
const {
    inputDate,
    showDateEditor,
    showConfirmationModal,
    saving,
    getLongDate,
    getInputLongDate,
    disableSave,
    flowInitiated,
    toggleDateEditor,
    toggleConfirmationModal,
    onSaveClick,
    onConfirm
} = useFlowDate({
    date: toRef(props, 'date'),
    flowId: toRef(props, 'flowId'),
    flowStatus: toRef(props, 'flowStatus'),
    onDateUpdated: updatedFlow => emit('flow-date-updated', updatedFlow)
});
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@/views/staff/ChatbotFlowList/components/FlowsV2/styles/mixins';

// Component styles
.flow-date-editor {
    .date-editor {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0.5rem;

        .actions {
            display: flex;
            gap: 1rem;
            justify-content: space-between;

            :deep(.ui-core-base-button) {
                width: 100%;
                button {
                    width: 100%;
                }
            }
        }
    }

    .date {
        cursor: pointer;
        padding: 0.5rem;

        &.editable {
            @include editable-indicator;
        }

        &.readonly {
            cursor: auto;
        }
    }
}

// Modal styles
.confirmation-modal-content {
    margin-top: 5px;
    margin-bottom: 5px;
    .date {
        font-size: $large-font-size;
    }
}
</style>
