<template>
    <div class="chips-content">
        <div class="chips-container edsights-primevue-theme">
            <BaseChip
                v-for="chip in chips"
                :key="chip.value"
                :label="chip.label"
                variant="blue"
                :title="chip.tooltip || chip.label"
                class="label-chip"
            />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { BaseChip } from '@edsights/ui-core';

const props = defineProps({
    tags: {
        type: Array,
        default: () => []
    }
});

const chips = computed(() => {
    const items = props.tags;
    if (!Array.isArray(items)) {
        return [];
    }

    const chips = items.reduce((acc, item) => {
        const { text, id } = item;
        if (typeof text === 'string' && typeof id === 'number') {
            acc.push({ label: text, value: id });
        }
        return acc;
    }, []);

    if (chips.length > 3) {
        const firstThree = chips.slice(0, 3);
        const remaining = chips.slice(3);
        const consolidatedTooltip = remaining.map(obj => obj.label).join(', ');

        return [...firstThree, {
            label: '...',
            value: 'more',
            tooltip: consolidatedTooltip
        }];
    }

    return chips;
});
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.chips-content {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0;

    .chips-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.75rem;

        .label-chip {
            display: flex;
            gap: 0.5rem;
            padding: 0.25rem 0.75rem;
        }
    }
}
</style> 
