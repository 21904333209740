<template>
    <BaseModal
        class="schools-modal-container"
        header="Assign Templates to School"
        :style="{ width: '50rem', height: '40rem' }"
        :contentStyle="{ height: '100%' }"
        :showActions="true"
        :loading="saving"
        :disableAction="disableSave"
        @close="onCancel"
        @handleAction="onSave"
    >
        <div class="container modal-content">
            <LoadingSpinner v-if="loading" />
            <TextInputWithSearch
                v-else
                placeholder="Search for a school..."
                :options="schoolOptions"
                v-model="searchValue"
                @option-selected="onOptionSelected"
            />
        </div>
    </BaseModal>
</template>

<script>
import { BaseModal } from '@edsights/ui-core';
import TextInputWithSearch from '@/components-deprecated/global/TextInputWithSearch';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import { listSchools } from '@/api/school';

export default {
    name: 'SchoolsModal',
    components: { LoadingSpinner, TextInputWithSearch, BaseModal },
    props: {
        saving: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            schoolOptions: [],
            searchValue: '',
            selectedSchoolOption: null,
            createMode: false,
            createValue: '',
            creating: false,
            loading: false
        };
    },
    async created() {
        this.loading = true;

        // back end requires page and pageSize.
        // here we load a large single page containing all schools so we can "search" by name in the UI
        // todo: add support for a "search" param to BE route and search by name here
        const schools = await listSchools({
            page: 1,
            page_size: 500,
            isActive: true
        });

        if (typeof schools === 'object' && Array.isArray(schools.results)) {
            this.schoolOptions = schools.results.map(s => ({ value: s.id, label: s.name }));
            this.loading = false;
        }
    },
    methods: {
        onOptionSelected(option) {
            this.selectedSchoolOption = option;
        },
        async onSave() {
            if (
                typeof this.selectedSchoolOption === 'object' &&
                typeof this.selectedSchoolOption.value === 'string' &&
                typeof this.selectedSchoolOption.label === 'string'
            ) {
                this.$emit('onSave', {
                    id: this.selectedSchoolOption.value,
                    name: this.selectedSchoolOption.label
                });
            }
        },
        onCancel() {
            this.$emit('onClose');
        }
    },
    computed: {
        disableSave() {
            return !this.selectedSchoolOption;
        }
    }
};
</script>

<style lang="scss" scoped>
@import 'input-style-mixin.scss';

.schools-modal-container {
    .modal-content {
        :deep(input) {
            @include input-style();
        }
        :deep(.menu-container) {
            width: 100%;
        }
    }
}
</style>
