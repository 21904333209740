import Pagination from '@/services/pagination';
import {
    getTouchpointsBySchoolId,
    getTouchpointsBySchoolIdWithInterventionStats
} from '@/api/touchpoint';
import { list as listIntegrations } from '@/api/integration';
import { getChatbotFlows } from '@/api/chatbot-flow';
import { getStudentConversation } from '@/api/student-messages';
import { listSchools } from '@/api/school';
import { listStudentVoiceScoreTagCategories } from '@/api/student-voice-score';

const listTypes = {
    touchpoints: {
        listFunction: getTouchpointsBySchoolId
    },
    touchpointsWithInterventionStats: {
        listFunction: getTouchpointsBySchoolIdWithInterventionStats
    },
    integrations: {
        listFunction: listIntegrations
    },
    chatbotFlows: {
        listFunction: getChatbotFlows
    },
    studentConversation: {
        listFunction: getStudentConversation
    },
    schools: {
        listFunction: listSchools
    },
    svsTagCategories: {
        listFunction: listStudentVoiceScoreTagCategories
    }
};

/**
 * Copied from CollectionManager for use with v2 endpoints (Node).
 **/
export default class v2CollectionManager {
    constructor({
        list = [],
        listInfo = {},
        refreshing = false,
        loadingNextPage = false,
        filters = {},
        _previousFilters = {},
        listType = null,
        errorHandlerOptions = {},
        paginationOptions = {
            size: 10
        }
    } = {}) {
        Object.assign(this, {
            list,
            listInfo,
            paginationOptions,
            pagination: Pagination.create(paginationOptions),
            refreshing,
            loadingNextPage,
            filters,
            _previousFilters,
            listType,
            errorHandlerOptions
        });
    }

    // Factory Function
    static create(opts = {}) {
        opts = opts || {};
        return new v2CollectionManager(opts);
    }

    /**
     * Update the collection.
     *
     * @param {object} data - API response data
     * @param {boolean} append - Optional, add to the current list if `true`. Replace the
     *                           current list if false. Defaults to `false`.
     */
    update(data, append = false) {
        const { results, previous, next, count, ...rest } = data;
        this.list = [...(append ? this.list : []), ...results];

        // Any properties other than the results and pagination properties will be placed into listInfo.
        // This allows for supplying any additional info about the list that isn't contained in the list itself.
        this.listInfo = rest;
        this.pagination = Pagination.create({
            ...this.pagination,
            next: next,
            previous: previous,
            totalCount: count
        });

        return this;
    }

    /**
     * Update an item in the collection.
     * @param {string} id - The id of the item to update
     * @param {object} data - The data that will be updated on the item. This can contain one or multiple keys to update.
     */
    updateItem(id, data) {
        this.list = this.list.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    ...data
                };
            }

            return item;
        });
    }

    /**
     * Refresh the collection.
     */
    async refresh() {
        this.refreshing = true;

        // If filters have changed, reset pagination
        if (!_.isEqual(this.filters, this._previousFilters)) {
            this.pagination = Pagination.create(this.paginationOptions);
        }

        // Save value of current filters, so we can check to see if they've changed later
        this._previousFilters = _.cloneDeep(this.filters);
        const params = {
            page: this.pagination.page,
            page_size: this.pagination.size,
            errorHandlerOptions: this.errorHandlerOptions,
            ...this.filters
        };

        try {
            const response = await listTypes[this.listType].listFunction({ ...params });
            return this.update(response);
        } finally {
            this.refreshing = false;
        }
    }

    /**
     * Advance to the next page and refresh the collection.
     */
    nextPage() {
        this.pagination.setNextPage();
        return this.refresh();
    }

    /**
     * Go back to the previous page and refresh the collection.
     */
    prevPage() {
        this.pagination.setPrevPage();
        return this.refresh();
    }

    /**
     * Get the next page for a collection and add this page
     * to the collection.
     */
    async addNextPage() {
        if (this.pagination.next === null) {
            return;
        }
        this.pagination.setNextPage();

        this.loadingNextPage = true;
        const params = {
            ...this.filters,
            page: this.pagination.page,
            page_size: this.pagination.size,
            errorHandlerOptions: this.errorHandlerOptions
        };

        try {
            const response = await listTypes[this.listType].listFunction({ ...params });
            return this.update(response, true);
        } finally {
            this.loadingNextPage = false;
        }
    }
}
