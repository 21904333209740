<template>
    <div class="delete-label-modal-container">
        <BaseModal
            header="Delete Label"
            actionText="Delete"
            :loading="saving"
            :showActions="true"
            @close="onCancel"
            @handleAction="onSave"
        >
            <div class="container modal-content">
                Are you sure you want to delete the label?
            </div>
        </BaseModal>
    </div>
</template>

<script>
import { BaseModal } from '@edsights/ui-core';

export default {
    name: 'DeleteLabelModal',
    components: { BaseModal },
    props: {
        labelText: {
            type: String,
            required: true
        },
        saving: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        onSave() {
            this.$emit('onSave');
        },
        onCancel() {
            this.$emit('onCancel');
        }
    }
};
</script>

<style lang="scss" scoped>
.delete-label-modal-container {
    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
</style>
