import axios from 'axios';
import { strictEqual } from 'assert';
import * as Api from './index';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';

export const getStudentVoiceScoreSummaryBySchoolId = async ({
    schoolId,
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof schoolId, 'string', 'schoolId must be a string');
        const url = Api.getv2ApiBase(`/school/${schoolId}/student-voice-score/summary`);
        const options = Api.getOptions();

        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};

export const getStudentVoiceScoreBySchoolIdAndTagId = async ({
    schoolId,
    tagId,
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof schoolId, 'string', 'schoolId must be a string');
        strictEqual(typeof tagId, 'string', 'tagId must be a string');
        const url = Api.getv2ApiBase(`/school/${schoolId}/student-voice-score-by-tag/${tagId}`);
        const options = Api.getOptions();

        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};

export const listStudentVoiceScoreTagCategories = async ({
    schoolId,
    page = 1,
    page_size = 25,
    search = '',
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof schoolId, 'string', 'schoolId must be a string');
        const url = Api.getv2ApiBase(`/school/${schoolId}/student-voice-score-tag-categories`);

        const options = Api.getOptions();
        const params = {
            page,
            page_size
        };

        if (search && typeof search === 'string') {
            params.search = search;
        }
        const response = await axios.get(url, { ...options, params });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};

export const getStudentVoiceScoreByInstitutionTypeIds = async ({
    schoolId,
    institutionTypeIds,
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof schoolId, 'string', 'schoolId must be a string');
        strictEqual(
            Array.isArray(institutionTypeIds) &&
                institutionTypeIds.length > 0 &&
                institutionTypeIds.every(id => typeof id === 'string'),
            true,
            'institutionTypeIds must be an array with at least 1 string'
        );

        const url = Api.getv2ApiBase(`/student-voice-score/average-for-institution-types`);
        const options = Api.getOptions();

        const params = {
            institutionTypeIds: institutionTypeIds.join(',')
        };

        const response = await axios.get(url, { ...options, params });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};

export const getStudentVoiceScoreTagPerformanceBySchoolIdAndTagCategoryIds = async ({
    schoolId,
    tagCategoryIds,
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof schoolId, 'string', 'schoolId must be a string');
        let data = [];

        if (tagCategoryIds.length > 0) {
            strictEqual(
                Array.isArray(tagCategoryIds) &&
                    tagCategoryIds.length > 0 &&
                    tagCategoryIds.every(id => typeof id === 'string'),
                true,
                'tagCategoryIds must be an array with at least 1 string'
            );
            data = tagCategoryIds;
        }

        const url = Api.getv2ApiBase(`/school/${schoolId}/student-voice-score-performance`);
        const options = Api.getOptions();

        const response = await axios.post(
            url,
            data,
            options
        );

        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};
