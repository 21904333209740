<template>
    <BaseModal
        class="rename-label-modal-container"
        header="Rename Label"
        :style="{ width: '25rem' }"
        :disableAction="disableSave"
        :loading="saving"
        :showActions="true"
        @close="onCancel"
        @handleAction="onSave"
    >
        <div class="container modal-content">
            <TextInput placeholder="Rename label..." v-model="textInputValue" />
        </div>
    </BaseModal>
</template>

<script>
import { BaseModal } from '@edsights/ui-core';
import TextInput from '@/components-deprecated/global/TextInput';

export default {
    name: 'RenameLabelModal',
    components: { BaseModal, TextInput },
    props: {
        saving: {
            type: Boolean,
            default: false
        },
        labelText: {
            type: String,
            required: true
        }
    },
    created() {
        this.textInputValue = this.labelText;
    },
    data() {
        return {
            textInputValue: ''
        };
    },
    methods: {
        onSave() {
            this.$emit('onSave', this.textInputValue);
        },
        onCancel() {
            this.$emit('onCancel');
        }
    },
    computed: {
        disableSave() {
            return this.textInputValue === this.labelText || !this.textInputValue;
        }
    }
};
</script>

<style lang="scss" scoped>
@import 'input-style-mixin.scss';

.rename-label-modal-container {
    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        :deep(input) {
            @include input-style();
        }
    }
}
</style>
