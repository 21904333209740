import { ref, onMounted } from 'vue';
import v2CollectionManager from '@/services/v2CollectionManager';
import { listTagsForSchool as listTagsForSchoolApi } from '@/api/tag';
import Alerts from '@/lib/alerts';

export function useSchools() {
    const schoolsList = ref(v2CollectionManager.create({
        listType: 'schools',
        paginationOptions: {
            size: 25
        },
        filters: {
            isActive: true,
            search: null
        }
    }));

    const isSchoolsLoading = ref(false);

    onMounted(async () => {
        await schoolsList.value.refresh();
    });

    const loadNextPage = async () => {
        isSchoolsLoading.value = true;
        try {
            await schoolsList.value.addNextPage();
        } finally {
            isSchoolsLoading.value = false;
        }
    };

    const listTagsForSchool = async (schoolId) => {
        try {   
            const tags = await listTagsForSchoolApi({ schoolId });
            return tags;
        } catch (error) {
            Alerts.alert({
                type: 'error',
                message: 'There was a problem loading the tags. Please try again.'
            });
        }
    };

    const searchSchools = async (searchTerm) => {
        isSchoolsLoading.value = true;
        try {
            schoolsList.value.filters.search = searchTerm;
            await schoolsList.value.refresh();
        } finally {
            isSchoolsLoading.value = false;
        }
    };

    return {
        schoolsList,
        isSchoolsLoading,
        loadNextPage,
        searchSchools,
        listTagsForSchool
    };  
} 
