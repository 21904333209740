<template>
    <div class="distribution-container">
        <div class="title">SVS<sup>®</sup> Distribution</div>
        <div class="subtitle" v-if="showSubtitle">
            {{ totalCount }} student(s) responded
        </div>
        <div class="distribution-row">
            <div class="percent-container">
                <div class="percent promoters">{{ renderedPromoters.percentage }}</div>
                <div class="percent-label">Promoters ({{ renderedPromoters.count }})</div>
            </div>
            <div class="percent-container">
                <div class="percent passives">{{ renderedPassives.percentage }}</div>
                <div class="percent-label">Passives ({{ renderedPassives.count }})</div>
            </div>
            <div class="percent-container">
                <div class="percent detractors">{{ renderedDetractors.percentage }}</div>
                <div class="percent-label">Detractors ({{ renderedDetractors.count }})</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Distribution',
    props: {
        detractorCount: {
            type: Number,
            required: false
        },
        passiveCount: {
            type: Number,
            required: false
        },
        promoterCount: {
            type: Number,
            required: false
        },
        showSubtitle: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getRenderedCountAndPercentage({ count, totalCount }) {
            const rendered = {
                count: '--',
                percentage: '--'
            };

            if (typeof count === 'number' && typeof totalCount === 'number' && totalCount > 0) {
                rendered.count = count;
                rendered.percentage = `${Math.round((count / totalCount) * 100)}%`;
            }

            return rendered;
        }
    },
    computed: {
        totalCount() {
            if (
                typeof this.promoterCount === 'number' &&
                typeof this.passiveCount === 'number' &&
                typeof this.detractorCount === 'number'
            ) {
                return this.promoterCount + this.passiveCount + this.detractorCount;
            }

            return null;
        },
        renderedPromoters() {
            return this.getRenderedCountAndPercentage({
                count: this.promoterCount,
                totalCount: this.totalCount
            });
        },
        renderedPassives() {
            return this.getRenderedCountAndPercentage({
                count: this.passiveCount,
                totalCount: this.totalCount
            });
        },
        renderedDetractors() {
            return this.getRenderedCountAndPercentage({
                count: this.detractorCount,
                totalCount: this.totalCount
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.distribution-container {
    .title {
        font-weight: bold;
    }

    .distribution-row {
        display: flex;
        align-items: center;
        padding-top: 10px;
        .percent-container {
            position: relative;
            padding-right: 24px;
            margin-right: 24px;

            // separator between percents
            &:not(:last-child)::after {
                content: '';
                position: absolute;
                right: 0;
                top: 25%;
                bottom: 25%;
                border-right: 2px solid $silver;
            }

            .percent {
                font-size: 16px;

                &.promoters {
                    color: $green;
                }
                &.passives {
                    color: $accessible-gray;
                }
                &.detractors {
                    color: $amaranth;
                }
            }

            .percent-label {
                font-size: 10px;
                color: $accessible-gray;
            }
        }
    }
}
</style>
