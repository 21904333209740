<template>
    <div class="actions-content">
        <BaseIcon icon="clone" @click.stop="$emit('create-template', props.id)" />
        <a v-if="showEditIcon" v-bind="editLinkProps">
            <BaseIcon icon="pencil" />
        </a>
        <BaseIcon v-if="showCancelIcon" icon="trash" @click.stop="openCancelFlowModal" />
    </div>
</template>

<script setup>
import { CHATBOT_FLOW_STATUS } from '@/consts/chatbot-flow';
import { BaseIcon } from '@edsights/ui-core';
import { computed } from 'vue';

const props = defineProps({
    id: {
        type: String,
        required: true
    },
    status: {
        type: String,
        required: true
    },
    historical: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['open-cancel-flow-modal', 'create-template']);

const editLinkProps = computed(() => ({
    href: `/chatbot-flow/${props.id}`,
    target: '_blank',
    'aria-disabled': props.historical ? 'true' : 'false',
    class: { disabled: props.historical }
}));

const showEditIcon = computed(() =>
    [
        CHATBOT_FLOW_STATUS.SCHEDULED.value,
        CHATBOT_FLOW_STATUS.DRAFT.value,
        CHATBOT_FLOW_STATUS.INITIATED.value
    ].includes(props.status)
);

const showCancelIcon = computed(() =>
    [CHATBOT_FLOW_STATUS.SCHEDULED.value, CHATBOT_FLOW_STATUS.DRAFT.value].includes(props.status)
);

const openCancelFlowModal = () => {
    emit('open-cancel-flow-modal', props.id);
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.actions-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 0.75rem;

    :deep(.ui-core-base-icon) {
        cursor: pointer;
    }
}

a.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
