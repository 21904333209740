<template>
    <div class="name-column">
        <a
            :href="`/chatbot-flow/${id}`"
            target="_blank"
        >
            <h3 title="Click to edit">
                {{ props.name }}
            </h3>
        </a>
        <img
            v-tooltip="props.touchpointDescription"
            class="icon"
            src="@/assets/icons/question-mark.svg"
            alt="Question mark"
            tabindex="0"
        />
    </div>
</template>

<script setup>
const props = defineProps({
    id: {
        type: String,
        required: true
    },
    name: {
        type: String,
        required: true
    },
    touchpointDescription: {
        type: String,
        default: ''
    }
});
</script>

<style lang="scss" scoped>
.name-column {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    h3 {
        cursor: pointer;
        margin: 0;
    }

    .icon {
        width: 12px;
        cursor: pointer;
    }
}
</style> 
