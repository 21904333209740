<template>
    <div class="flows-view-filters">
        <!-- Filters -->
        <BaseFilter
            v-model="filters"
            :options="filterOptions"
            @update:modelValue="handleFilter"
            @asyncFilter="handleAsyncFilter"
        >
            <template #select-type="slotProps">
                <div class="type-option">
                    <TouchpointIcon
                        v-if="slotProps.option.value"
                        :is-custom="slotProps.option.value === 'Custom'"
                        :alt="
                            slotProps.option.value === 'Custom'
                                ? 'Feather representing a custom touchpoint'
                                : 'EdSights logo representing a non-custom touchpoint'
                        "
                    />
                    <span>{{ slotProps.option.label }}</span>
                </div>
            </template>
        </BaseFilter>

        <!-- Chips for active filters -->
        <div class="chips-wrapper" v-if="filterChips.length">
            <div class="chips-container edsights-primevue-theme">
                <BaseChip
                    v-for="chip in filterChips"
                    :key="chip.value"
                    :label="chip.label"
                    :removable="true"
                    variant="blue"
                    @remove="handleRemoveFilterChip(chip)"
                    class="filter-chip"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
// Vue core
import { defineProps, defineEmits, ref } from 'vue';
import { debounce } from 'lodash';

// UI Components - Core
import { BaseFilter, BaseChip } from '@edsights/ui-core';

// Components
import { TouchpointIcon } from '@/views/staff/ChatbotFlowList/components/FlowsV2/components';

// Composables
import { useFilters, useFilterChips, useSchools } from '../composables';

// props
const props = defineProps({
    labels: {
        type: Array,
        default: () => []
    }
});

// refs
const flowNameFilter = ref('');

// emits
const emit = defineEmits(['filter', 'create-new']);

// composables
const { filters, filterOptions, handleAsyncFilter, schoolsList, tags } = useFilters(props.labels);
const { filterChips, removeFilterChip } = useFilterChips(filters, schoolsList, tags, props.labels);
const { listTagsForSchool } = useSchools();

// functions
const debouncedEmitNameFilter = debounce(() => {
    flowNameFilter.value = filters.value.name;
    emit('filter', filters.value);
}, 300);

const handleFilter = async () => {
    if (filters.value.schoolId) {
        tags.value = await listTagsForSchool(filters.value.schoolId);
    }

    // If only the name filter changed, debounce the emission
    if (filters.value.name !== undefined && filters.value.name !== flowNameFilter.value) {
        debouncedEmitNameFilter();
    } else {
        // For all other filter changes, emit immediately
        emit('filter', filters.value);
    }
};

const handleRemoveFilterChip = chip => {
    const updatedFilters = removeFilterChip(chip);
    emit('filter', updatedFilters);
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.flows-view-filters {
    margin-bottom: 30px;

    .filter-section {
        position: relative;
    }

    :deep(.ui-core-base-filter) {
        display: flex;
        justify-content: space-between;
        gap: 1.5rem;

        @media screen and (max-width: 1230px) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            > .ui-core {
                max-width: 280px !important;
            }
        }

        > .ui-core {
            flex: 1;
            width: auto;
            max-width: 155px;
        }
    }

    .chips-wrapper {
        margin-bottom: 15px;

        .chips-container {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 0.75rem;
            margin-top: 15px;

            .filter-chip {
                display: flex;
                padding: 0.25rem 0.75rem;
                gap: 0.5rem;
            }
        }
    }
}

.type-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
</style>
