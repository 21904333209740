<template>
    <div class="flows-view">
        <FlowsViewHeader :isApplyingFilters="isApplyingFilters" />
        <div class="box">
            <div class="base-loading-container" v-if="initialLoading">
                <BaseLoading />
            </div>
            <div v-if="!initialLoading">
                <!-- Filters -->
                <FlowsViewFilters :labels="labels" @filter="onFilter" />
                <!-- Table -->
                <FlowsViewTable
                    :tableData="tableData"
                    :loading="initialLoading"
                    :loadingNextPage="flowsCollection.loadingNextPage"
                    :selectedRowId="selectedRowId"
                    :displayLoadMoreButton="!!flowsCollection.pagination.next"
                    :hasActiveFilters="hasActiveFilters"
                    @open-cancel-flow-modal="openCancelFlowModal"
                    @toggle-status="toggleStatus"
                    @flow-date-updated="onFlowDateUpdated"
                    @lazy-load="flowsCollection.addNextPage()"
                />
            </div>
        </div>
        <CancelFlowModal
            v-if="cancelFlowModalData.showModal"
            :flow="cancelFlowModalData.flow"
            @onClose="closeCancelFlowModal"
            @onSavedAsDraft="onSavedAsDraft"
            @onSavedAsCancelled="onSavedAsCancelled"
        />
        <ScheduleFlowModal
            v-if="scheduleFlowModalData.showModal"
            :chatbotFlowId="scheduleFlowModalData.chatbotFlow.id"
            :saving="scheduleFlowModalData.saving"
            @onClose="closeScheduleFlowModal"
            @onSave="scheduleChatbotFlow"
        />
    </div>
</template>

<script setup>
// Vue core
import { ref, computed, onMounted, reactive } from 'vue';
import { strictEqual } from 'assert';

// UI Components - Core
import { BaseLoading } from '@edsights/ui-core';

// lib
import Alerts from '@/lib/alerts';

// Flow-specific components
import {
    FlowsViewHeader,
    FlowsViewFilters,
    FlowsViewTable
} from '@/views/staff/ChatbotFlowList/components/FlowsV2/components';
import CancelFlowModal from '@/views/staff/ChatbotFlowList/components/CancelFlowModal';
import ScheduleFlowModal from '@/views/staff/ChatbotFlowList/components/ScheduleFlowModal';

// API services
import { findById, updateChatbotFlow } from '@/api/chatbot-flow';
import { getChatbotFlowLabels } from '@/api/chatbot-flow-labels';

// Services
import v2CollectionManager from '@/services/v2CollectionManager';

// Constants
import { CHATBOT_FLOW_STATUS, DRAFT_NULL_DATE_PLACEHOLDER } from '@/consts/chatbot-flow';

const initialLoading = ref(true);
const labels = ref([]);
const selectedRowId = ref(null);
const isApplyingFilters = ref(false);
const hasActiveFilters = ref(false);

// Cancel flow modal data
const cancelFlowModalDataDefaults = {
    showModal: false,
    flow: null
};
const cancelFlowModalData = ref({ ...cancelFlowModalDataDefaults });

// Schedule flow modal data
const scheduleFlowModalDataDefaults = {
    showModal: false,
    chatbotFlow: null,
    saving: false
};
const scheduleFlowModalData = ref({ ...scheduleFlowModalDataDefaults });

// Collection manager for flows
const flowsCollection = reactive(
    v2CollectionManager.create({
        listType: 'chatbotFlows',
        paginationOptions: {
            size: 50
        },
        filters: {
            schoolId: null,
            type: null,
            name: null,
            dateRange: null,
            labels: null,
            tags: null,
            statuses: [
                CHATBOT_FLOW_STATUS.DRAFT.value,
                CHATBOT_FLOW_STATUS.SCHEDULED.value,
                CHATBOT_FLOW_STATUS.INITIATED.value
            ]
        },
        errorHandlerOptions: {
            enableAlert: false,
            rethrow: true
        }
    })
);

const tableData = computed(() => {
    return flowsCollection.list.map(flow => ({
        id: flow.id,
        name: flow.templateRef.name,
        date: flow.date,
        labels: flow.labels,
        tags: flow.tags,
        status: flow.status,
        historical: flow.historical,
        touchpointRef: flow.touchpointRef,
        schoolsRef: flow.schoolsRef
    }));
});

// Methods
const loadFlows = async () => {
    await flowsCollection.refresh();
};

const openCancelFlowModal = flowId => {
    if (typeof flowId === 'string' && Array.isArray(flowsCollection.list)) {
        const flow = flowsCollection.list.find(f => f.id === flowId);
        if (typeof flow === 'object') {
            cancelFlowModalData.value = {
                ...cancelFlowModalDataDefaults,
                showModal: true,
                flow
            };
        }
    }
};

const closeCancelFlowModal = async () => {
    cancelFlowModalData.value = { ...cancelFlowModalDataDefaults };
};

const onSavedAsDraft = flowId => {
    if (typeof flowId === 'string' && Array.isArray(flowsCollection.list)) {
        // close modal and update component data to reflect change
        closeCancelFlowModal();
        flowsCollection.list = flowsCollection.list.map(flow => {
            if (flow.id === flowId) {
                return {
                    ...flow,
                    status: CHATBOT_FLOW_STATUS.DRAFT.value
                };
            }
            return flow;
        });
    }
};

const onSavedAsCancelled = flowId => {
    if (typeof flowId === 'string' && Array.isArray(flowsCollection.list)) {
        // close modal and update component data to reflect change
        closeCancelFlowModal();
        flowsCollection.list = flowsCollection.list.filter(flow => flow.id !== flowId);
    }
};

const onFlowDateUpdated = flow => {
    if (typeof flow === 'object' && typeof flow.id === 'string' && typeof flow.date === 'string') {
        const { id, date } = flow;
        flowsCollection.list = flowsCollection.list.map(flow => {
            if (flow.id === id) {
                return {
                    ...flow,
                    date
                };
            }
            return flow;
        });
    }
};

const onFlowScheduled = scheduledFlow => {
    if (typeof scheduledFlow === 'object' && typeof scheduledFlow.id === 'string') {
        flowsCollection.list = flowsCollection.list.map(flow => {
            if (flow.id === scheduledFlow.id) {
                return {
                    ...flow,
                    status: CHATBOT_FLOW_STATUS.SCHEDULED.value
                };
            }
            return flow;
        });
    }
};

const onFilter = async newFilters => {
    const { schoolId, type, name, dateAfter, dateBefore, labels, status, tags } = newFilters;

    Object.assign(flowsCollection.filters, {
        schoolId,
        type,
        name,
        dateAfter,
        dateBefore,
        labels,
        tags,
        statuses: status
            ? [status]
            : [
                  CHATBOT_FLOW_STATUS.DRAFT.value,
                  CHATBOT_FLOW_STATUS.SCHEDULED.value,
                  CHATBOT_FLOW_STATUS.INITIATED.value
              ]
    });

    // Update hasActiveFilters based on whether any filters are set
    hasActiveFilters.value = Boolean(
        schoolId ||
            type ||
            name ||
            dateAfter ||
            dateBefore ||
            (Array.isArray(labels) && labels.length > 0) ||
            (Array.isArray(tags) && tags.length > 0) ||
            status
    );

    isApplyingFilters.value = true;
    await loadFlows();
    isApplyingFilters.value = false;
};

const toggleStatus = flow => {
    if (
        !flow.date ||
        new Date(flow.date) < Date.now() ||
        flow.date === DRAFT_NULL_DATE_PLACEHOLDER
    ) {
        Alerts.alert({
            type: 'error',
            message: '<h2>Flow requires a valid date to be scheduled.</h2>',
            timeout: 3000
        });
    } else {
        scheduleFlowModalData.value = {
            ...scheduleFlowModalDataDefaults,
            showModal: true,
            chatbotFlow: flow
        };
    }
};

const closeScheduleFlowModal = () => {
    scheduleFlowModalData.value = { ...scheduleFlowModalDataDefaults };
};

const scheduleChatbotFlow = async studentCount => {
    try {
        strictEqual(typeof studentCount, 'number');

        scheduleFlowModalData.value.saving = true;

        // refetch flow in case it was already scheduled
        const flow = await findById({
            id: scheduleFlowModalData.value.chatbotFlow.id,
            includeFull: false,
            errorHandlerOptions: { rethrow: false, enableAlert: true }
        });

        if (flow._Status === 'SCHEDULED') {
            Alerts.alert({
                type: 'error',
                message: 'Flow has already been scheduled.',
                timeout: 3000
            });

            closeScheduleFlowModal();
            return;
        }

        await updateChatbotFlow(
            flow.id,
            {
                status: CHATBOT_FLOW_STATUS.SCHEDULED.value
            },
            {
                rethrow: true,
                // enableAlert: true makes the backend's validation appear in an alert.
                // this should catch any invalid template bindings
                // (which are the only thing that could be invalid in this context).
                enableAlert: true,
                messagePrefix: 'There was a problem scheduling the flow:'
            }
        );

        Alerts.alert({
            type: 'success',
            message: '<h2>Chatbot flow scheduled!</h2>',
            timeout: 10000
        });

        onFlowScheduled(flow);
        closeScheduleFlowModal();
    } catch (error) {
        closeScheduleFlowModal();
    }
};

// Lifecycle hook
onMounted(async () => {
    try {
        initialLoading.value = true;
        await loadFlows();
        labels.value = await getChatbotFlowLabels();
    } catch (error) {
        Alerts.alert({
            type: 'error',
            message: 'There was a problem loading the flows. Please try again.'
        });
    } finally {
        initialLoading.value = false;
    }
});
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.flows-view {
    min-height: 100vh;

    .box {
        min-height: 500px;
    }

    .base-loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}
</style>
