<template>
    <div class="student-voice-score-card-container">
        <BaseCard>
            <template #content>
                <div class="student-voice-score-card-content">
                    <div class="student-voice-score-card-header">
                        <div class="student-voice-score-card-header__title">
                            <h2>{{ title }}</h2>
                            <BaseIcon
                                icon="questioncircle"
                                class="student-voice-score-card-header__title__helper-icon"
                                v-tooltip="titleTooltip"
                            />
                        </div>
                        <div class="student-voice-score-card-header__subtitle">
                            Understand your overall score
                        </div>
                    </div>

                    <BaseDivider />

                    <div class="student-voice-score-card-body">
                        <div class="card-filter-row">
                            <div class="card-filter">
                                <div class="card-filter__label-row">
                                    <p class="card-filter__label">
                                        Filter By Tag
                                    </p>
                                    <BaseIcon
                                        icon="questioncircle"
                                        class="card-filter__helper-icon"
                                        v-tooltip="'Your school\'s latest SVS for a particular tag'"
                                    />
                                </div>
                                <BaseSelect
                                    class="card-filter__dropdown"
                                    label="Filter By Tag"
                                    :modelValue="selectedTag"
                                    :options="tagCategoriesOptions"
                                    :loading="loading"
                                    :hideLabel="true"
                                    :showClear="true"
                                    optionLabel="name"
                                    optionValue="id"
                                    optionGroupLabel="name"
                                    optionGroupValue="id"
                                    optionGroupChildren="tagsRef"
                                    optionGroupIcon="taggroup"
                                    :asyncFilter="true"
                                    @handleFilter="handleFilterTags"
                                    @loadOptionsNextPage="onTagCategoriesNextPage"
                                    @update:modelValue="handleUpdateSelectedTag"
                                />
                            </div>

                            <div class="card-filter">
                                <div class="card-filter__label-row">
                                    <p class="card-filter__label">
                                        Compare Against
                                    </p>
                                    <BaseIcon
                                        icon="questioncircle"
                                        class="card-filter__helper-icon"
                                        v-tooltip="
                                            'Peer group benchmark that is relevant to your institution'
                                        "
                                    />
                                </div>
                                <BaseMultiSelect
                                    class="card-filter__dropdown"
                                    label="Compare Against"
                                    :modelValue="selectedInstitutionTypes"
                                    :options="institutionTypeCategories"
                                    :loading="loading"
                                    :selectionLimit="15"
                                    :maxSelectedLabels="1"
                                    :hideLabel="true"
                                    display="comma"
                                    optionLabel="name"
                                    optionValue="id"
                                    optionGroupLabel="name"
                                    optionGroupValue="id"
                                    optionGroupChildren="institutionTypes"
                                    optionGroupIcon="institution"
                                    :filter="true"
                                    @update:modelValue="handleUpdateInstitutionTypes"
                                />
                            </div>
                        </div>
                        <LoadingSpinner v-if="loading" />
                        <div class="empty-data-container" v-else-if="isEmpty">
                            <EmptyState
                                icon="#icon-svs-chart"
                                message="Not enough data collected"
                                :sub-message="emptyStateMessage"
                            />
                        </div>
                        <template v-else>
                            <div class="data-container">
                                <div class="score-container">
                                    <ScoreProgress
                                        :score="score"
                                        :comparedScore="comparedScore"
                                        :comparedScoreLabel="comparedScoreLabel"
                                        :differenceLabelPrefix="differenceLabelPrefix"
                                        emptyStateMessage="No comparison data available to generate a benchmark."
                                        showScoreGradient
                                        showProgressBarColors
                                    />
                                </div>
                                <div class="data-container distribution-download">
                                    <div>
                                        <Distribution
                                            :promoterCount="promoterCount"
                                            :passiveCount="passiveCount"
                                            :detractorCount="detractorCount"
                                            :showSubtitle="true"
                                        />
                                    </div>
                                    <div class="data-container download">
                                        <ReportRequestDownloadButton
                                            reportType="CHATBOT_CONVERSATIONS"
                                            ariaLabel="Download Full Report"
                                            buttonText="Download Full Report"
                                            :reportOptions="{
                                                chatbotFlowId,
                                                cleanOutput: true
                                            }"
                                        />
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </BaseCard>
    </div>
</template>

<script setup>
import Distribution from './components/Distribution/Distribution.vue';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import ScoreProgress from '../components/ScoreProgress/ScoreProgress.vue';
import EmptyState from '@/components-deprecated/global/EmptyState';
import ReportRequestDownloadButton from '@/components-deprecated/ReportRequestDownloadButton';
import CollectionManager from '@/services/collectionManager';
import TagCategories from '@/services/tagCategories';
import * as InstitutionTypeCategoryApi from '@/api/institution-type-category';
import { computed, defineEmits, defineProps, onBeforeMount, ref } from 'vue';
import v2CollectionManager from '@/services/v2CollectionManager';
import { BaseCard, BaseDivider, BaseIcon, BaseMultiSelect, BaseSelect } from '@edsights/ui-core';
import { useStore } from 'vuex';

const props = defineProps({
    comparedScore: {
        type: Number,
        required: false
    },
    comparedScoreLabel: {
        type: String,
        required: false
    },
    score: {
        type: Number,
        required: false
    },
    detractorCount: {
        type: Number,
        required: false
    },
    passiveCount: {
        type: Number,
        required: false
    },
    promoterCount: {
        type: Number,
        required: false
    },
    title: {
        type: String,
        default: 'Student Voice Score'
    },
    titleTooltip: {
        type: String,
        default: "Your school's latest SVS"
    },
    loading: {
        type: Boolean,
        default: false
    },
    differenceLabelPrefix: {
        type: String,
        required: false
    },
    chatbotFlowId: {
        type: String,
        required: false
    },
    enableSVSTagsFeatureFlag: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['filterChanged', 'institutionTypeChanged']);

const store = useStore();

const tagCategories = props.enableSVSTagsFeatureFlag
    ? v2CollectionManager.create({
          listType: 'svsTagCategories',
          filters: {
              schoolId: store.state.user.schoolRef.id
          },
          errorHandlerOptions: {
              enableAlert: true,
              rethrow: false
          }
      })
    : CollectionManager.create({
          ModelClass: TagCategories,
          filters: { has_students: true },
          v2: true,
          slim: true
      });

const tagCategoriesOptions = ref([]);
const selectedTag = ref(null);
const institutionTypeCategories = ref([]);
const selectedInstitutionTypes = ref([]);

async function getInstitutionTypeCategories() {
    institutionTypeCategories.value = await InstitutionTypeCategoryApi.listInstitutionTypeCategories(
        {
            errorHandlerOptions: {
                rethrow: false,
                enableAlert: true
            }
        }
    );
}

async function getTagCategories(shouldRefresh = true) {
    if (shouldRefresh) {
        await tagCategories.refresh();
    }

    tagCategoriesOptions.value = tagCategories?.list ?? [];
}

function handleUpdateSelectedTag(value) {
    selectedTag.value = value;

    emit('filterChanged', {
        tagIds: value?.toString() ?? null
    });
}

function handleUpdateInstitutionTypes(value) {
    selectedInstitutionTypes.value = value;

    emit('institutionTypeChanged', value);
}

const hasScore = computed(() => {
    return typeof props.score === 'number';
});

const isEmpty = computed(() => {
    return (!props.loading && !hasScore) || (!props.loading && totalStudents.value === 0);
});

const totalStudents = computed(() => {
    // Use the nullish coalescing operator (??) to default to 0 if the prop is null or undefined
    const promoterCount = props.promoterCount ?? 0;
    const passiveCount = props.passiveCount ?? 0;
    const detractorCount = props.detractorCount ?? 0;

    return promoterCount + passiveCount + detractorCount;
});

const emptyStateMessage = computed(() => {
    if (selectedTag.value) {
        return (
            "There currently isn't enough data on the selected tag to generate a score." +
            ' Try again by selecting a different tag with more students.'
        );
    }

    return 'Stay tuned - your Student Voice Score Summary will be coming soon.';
});

async function onTagCategoriesNextPage() {
    if (tagCategories.pagination.next != null) {
        await tagCategories.addNextPage();
        await getTagCategories(false);
    }
}

async function handleFilterTags(searchText) {
    tagCategories.filters.search = null;

    if (searchText) {
        tagCategories.filters.search = searchText;
    }

    await getTagCategories();
}

onBeforeMount(() => {
    getTagCategories();
    getInstitutionTypeCategories();
});
</script>

<style lang="scss" scoped src="./student-voice-score-card.scss"></style>
