<template>
    <div class="flow-school-info">
        <LoadingSpinner v-if="loading" />
        <Table v-else :columns="tableColumns" :data="tableData" :showHeader="false">
            <template v-slot:rows="{ rows }">
                <tr v-for="row in rows" :key="row.id">
                    <td>
                        {{ row.school }}
                    </td>
                    <td>
                        {{ row.sent }}
                    </td>
                    <td>
                        {{ row.delivered }}
                    </td>
                    <td>
                        {{ row.nudges }}
                    </td>
                    <td>
                        {{ row.responded }}
                    </td>
                    <td>
                        {{ row.errorCount }}
                    </td>
                    <td class="download">
                        <div class="content">
                            <ReportRequestDownloadButton
                                reportType="CHATBOT_CONVERSATIONS"
                                buttonText=""
                                :reportOptions="{
                                    chatbotFlowId: flowId,
                                    cleanOutput: false,
                                    skipFilters: true
                                }"
                            />
                        </div>
                    </td>
                </tr>
            </template>
        </Table>
    </div>
</template>

<script>
import Table from '@/components-deprecated/global/Table';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import * as ChatbotFlowApi from '@/api/chatbot-flow';
import ReportRequestDownloadButton from '@/components-deprecated/ReportRequestDownloadButton';

export default {
    name: 'FlowSchoolInfo',
    components: { ReportRequestDownloadButton, Table, LoadingSpinner },
    props: {
        flowId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            flow: null,
            loading: false,
            tableColumns: [
                {
                    label: 'School',
                    dataKey: 'school'
                },
                {
                    label: 'Sent',
                    dataKey: 'sent'
                },
                {
                    label: 'Delivered',
                    dataKey: 'delivered'
                },
                {
                    label: 'Responded',
                    dataKey: 'responded'
                },
                {
                    label: 'Errors',
                    dataKey: 'errorCount'
                }
            ]
        };
    },
    computed: {
        tableData() {
            if (this.flow) {
                return [
                    {
                        school: this.flow.schoolName,
                        sent: `${this.flow.messagesCreated} created`,
                        delivered: `${this.flow.messagesDelivered} delivered (${this.flow.messagesDeliveredPercentage}%)`,
                        responded: `${this.flow.studentsResponded} responded (${this.flow.studentsRespondedPercentage}%)`,
                        id: this.flow.schoolId,
                        errorCount: `${this.flow.twilioErrorCount} errors (${this.flow.twilioErrorPercentage}%)`,
                        nudges: `${this.flow.nudgeMessagesDelivered} nudged (${this.flow.nudgeMessagesPercentage}%)`
                    }
                ];
            }

            return [];
        }
    },
    async mounted() {
        try {
            this.loading = true;
            this.flow = await ChatbotFlowApi.findById({ id: this.flowId });
            this.loading = false;
        } catch (error) {
            this.$Alert.alert({
                type: 'error',
                message: "There was a problem getting the flow's information. Please try again."
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.flow-school-info {
    width: 100%;
    padding-left: 40px;

    &:deep(table) {
        th,
        td {
            padding: 0px 10px;
            width: 20% !important;
        }

        thead {
            background-color: white !important;
            th {
                font-size: 12px !important;
            }
        }

        tr {
            border-bottom: none !important;
            height: auto !important;

            td.download {
                .content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon-download.icon-enabled {
                        stroke: $primary-brand-color;
                    }
                }
            }
        }
    }
}
</style>
