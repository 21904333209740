<template>
    <img
        class="icon"
        :src="iconSrc"
        :alt="alt"
    />
</template>

<script setup>
import { computed } from 'vue';
import featherIcon from '@/assets/icons/feather.png';
import edsightsIcon from '@/assets/icons/edsights-e.png';

const props = defineProps({
    isCustom: {
        type: Boolean,
        required: true
    },
    alt: {
        type: String,
        default: ''
    }
});

const iconSrc = computed(() => 
    props.isCustom 
        ? featherIcon
        : edsightsIcon
);
</script>

<style lang="scss" scoped>
.icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
}
</style> 
