import { ref, computed } from 'vue';
import { formatLong, formatISO, formatHTMLDateTimeToISO, formatDateToDateTimeLocal } from '@/lib/dates';
import Alerts from '@/lib/alerts';
import { updateChatbotFlow } from '@/api/chatbot-flow';
import { CHATBOT_FLOW_STATUS, DRAFT_NULL_DATE_PLACEHOLDER } from '@/consts/chatbot-flow';

/**
 * Composable for managing flow date functionality
 * @param {Object} params
 * @param {Ref<string>} params.date - The current flow date
 * @param {Ref<string>} params.flowId - The ID of the flow
 * @param {Ref<string>} params.flowStatus - The current status of the flow
 * @param {Function} params.onDateUpdated - Callback when date is updated
 */
export function useFlowDate({ date, flowId, flowStatus, onDateUpdated }) {
    // State
    const inputDate = ref(null);
    const showDateEditor = ref(false);
    const showConfirmationModal = ref(false);
    const saving = ref(false);

    // Computed Properties
    const getDateTimeLocal = computed(() => {
        return formatDateToDateTimeLocal(inputDate.value);
    });

    const getLongDate = computed(() => {
        if (
            flowStatus.value === CHATBOT_FLOW_STATUS.DRAFT.value &&
            date.value === DRAFT_NULL_DATE_PLACEHOLDER
        ) {
            return 'Date TBD';
        }
        return formatLong(date.value);
    });

    const getInputLongDate = computed(() => {
        const isoDate = formatHTMLDateTimeToISO(getDateTimeLocal.value);
        return formatLong(isoDate);
    });

    const disableSave = computed(() => {
        const isoInputDate = formatHTMLDateTimeToISO(getDateTimeLocal.value);
        return date.value === isoInputDate;
    });

    const flowInitiated = computed(() => {
        return flowStatus.value === CHATBOT_FLOW_STATUS.INITIATED.value;
    });

    // Methods
    const toggleDateEditor = (show) => {
        if (!show) {
            inputDate.value = null;
            showDateEditor.value = false;
            return;
        }

        if (
            flowStatus.value === CHATBOT_FLOW_STATUS.DRAFT.value &&
            date.value === DRAFT_NULL_DATE_PLACEHOLDER
        ) {
            // draft flow has been previously saved with the null date placeholder.
            // clear out the date so it appears blank
            inputDate.value = null;
        } else {
            // format function returns empty string when invalid date provided
            // when empty, set inputDate to null for consistency with default state
            inputDate.value = formatISO(date.value, 'MM/dd/yyyy hh:mm a') || null;
        }

        showDateEditor.value = show;
    };

    const toggleConfirmationModal = (show) => {
        showConfirmationModal.value = show;
    };

    const isValid = () => {
        const date = formatHTMLDateTimeToISO(getDateTimeLocal.value);
        const now = new Date().toISOString();

        if (date <= now) {
            Alerts.alert({
                type: 'error',
                message: 'Date must be in the future.',
                timeout: 7000
            });
            return false;
        }

        return true;
    };

    const save = async () => {
        try {
            saving.value = true;

            let dateToSave;
            if (flowStatus.value === CHATBOT_FLOW_STATUS.DRAFT.value && !inputDate.value) {
                // editing a draft flow with no date selected - save the placeholder date
                dateToSave = DRAFT_NULL_DATE_PLACEHOLDER;
            } else {
                dateToSave = formatHTMLDateTimeToISO(getDateTimeLocal.value);
            }

            const updatedFlow = await updateChatbotFlow(flowId.value, { date: dateToSave });
            if (typeof updatedFlow === 'object') {
                onDateUpdated?.(updatedFlow);
            }
            toggleDateEditor(false);
            Alerts.alert({
                type: 'success',
                message: 'Flow date updated successfully.',
                timeout: 7000
            });
        } catch (error) {
            console.error('Error saving flow date:', error);
            Alerts.alert({
                type: 'error',
                message: 'There was a problem re-scheduling the flow. Please try again.',
                timeout: 7000
            });
        } finally {
            saving.value = false;
        }
    };

    const onSaveClick = () => {
        const valid = isValid();
        if (!valid) {
            return;
        }

        if (flowStatus.value === CHATBOT_FLOW_STATUS.SCHEDULED.value) {
            toggleConfirmationModal(true);
        } else {
            save();
        }
    };

    const onConfirm = () => {
        toggleConfirmationModal(false);
        save();
    };

    // Return composable interface
    return {
        inputDate,
        showDateEditor,
        showConfirmationModal,
        saving,
        getLongDate,
        getInputLongDate,
        disableSave,
        flowInitiated,
        toggleDateEditor,
        toggleConfirmationModal,
        onSaveClick,
        onConfirm
    };
} 
