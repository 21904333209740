<template>
    <div class="dashboard-container">
        <h1>Create School Dashboard</h1>

        <div class="dashboard-container__form">
            <BaseInputText
                v-for="field in textFields"
                v-model="formData[field.key]"
                :key="field.key"
                :label="field.label"
                :placeholder="field.placeholder"
            />
            <div class="dashboard-container__form__input-container">
                <label>Upload FAQ entries</label>
                <UploadFaqsCsv ref="uploadFaqsCsvRef" />
            </div>

            <div class="dashboard-container__form__checkbox">
                <BaseCheckbox
                    v-model="verificationChecked"
                    :disabled="registerTwilioOnCreation === false"
                />
                <p v-if="!verificationChecked">
                    Check box to display the phone number verification fields
                </p>
                <p v-if="verificationChecked">
                    Uncheck box to create school without phone verification
                </p>
            </div>

            <PhoneNumberVerificationForm
                v-if="verificationChecked"
                :formData="formDataPNV"
                @update:formData="updateFormDataPNV"
            />

            <Button @click="createDashboardHandler" v-if="!showLoadingSpinner">
                Create Dashboard
            </Button>
            <div v-else>
                <LoadingSpinner />
            </div>
        </div>

        <ul v-if="isDashboardCreated" class="list-container">
            <li v-for="log in logs" :key="log">
                {{ log }}
            </li>
        </ul>
    </div>
</template>

<script setup>
// vue
import { ref, reactive } from 'vue';

// lib
import Alerts from '@/lib/alerts';
import { enableFeatureFlag, featureFlagTypes } from '@/lib/feature-flag';

// api
import {
    registerSubAccount,
    submitPhoneVerification,
    validateTwilioVerification
} from '@/api/phone-number-registry';
import * as SchoolApi from '@/api/school';

// ui-core
import { BaseCheckbox, BaseInputText } from '@edsights/ui-core';

// components-deprecated
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/global/Button';

// components
import PhoneNumberVerificationForm from './PhoneNumberVerificationForm.vue';
import UploadFaqsCsv from './UploadFaqsCsv.vue';

// utils
import { createSchoolFormTextFields as textFields } from './consts/textFields';

// vars
const localTesting = false; // set to true to use testing form data
const notificationEmail = 'engineering@edsights.io';
const optInType = 'WEB_FORM';
const productionMessageSample =
    "Just a friendly reminder to complete your FAFSA by our priority deadline of May 1st to ensure you're considered for all possible financial aid. Reply STOP to opt out.";
const registerTwilioOnCreation =
    process.env.VUE_APP_CREATE_SCHOOL_AND_REGISTER_NUMBER_IN_TWILIO === 'true';
const useCaseCategories = ['CHARITY_NONPROFIT', 'HIGHER_EDUCATION'];
const useCaseSummary =
    'Support students via text, remind them of deadlines, and answer their questions.';

// refs
const isDashboardCreated = ref(false);
const logs = ref([]);
const showLoadingSpinner = ref(false);
const verificationChecked = ref(false);
const uploadFaqsCsvRef = ref(null);

// reactives
const formData = reactive(
    localTesting
        ? getTestingFormData()
        : {
              adminEmail: '',
              adminFirstName: '',
              adminLastName: '',
              schoolMascotName: '',
              schoolName: '',
              schoolNickname: '',
              temporaryPassword: ''
          }
);

let formDataPNV = reactive({
    additionalInformation: '',
    businessCity: '',
    businessContactEmail: '',
    businessContactFirstName: '',
    businessContactLastName: '',
    businessContactPhone: '',
    businessCountry: 'US',
    businessPostalCode: '',
    businessStateProvinceRegion: '',
    businessStreetAddress: '',
    businessWebsite: '',
    messageVolume: '',
    optInImageUrls: ''
});

// functions
function getTestingFormData() {
    return {
        adminEmail: `john.doe+${Math.random()
            .toString(36)
            .substring(2, 15)}@edsights.io`,
        adminFirstName: 'John',
        adminLastName: 'Doe',
        schoolMascotName: 'Eagle',
        schoolName: `Sample University ${Math.random()
            .toString(36)
            .substring(2, 15)}`,
        schoolNickname: 'SU',
        temporaryPassword: ''
    };
}

function resetForm() {
    Object.keys(formData).forEach(key => {
        formData[key] = '';
    });

    Object.keys(formDataPNV).forEach(key => {
        formDataPNV[key] = '';
    });
}

function updateFormDataPNV(value) {
    Object.assign(formDataPNV, value);
}

// async functions
async function createDashboardHandler() {
    showLoadingSpinner.value = true;
    try {
        let verificationPayload;
        const payload = {
            email: formData.adminEmail,
            firstName: formData.adminFirstName,
            lastName: formData.adminLastName,
            mascotName: formData.schoolMascotName,
            name: formData.schoolName,
            nickname: formData.schoolNickname,
            password: formData.temporaryPassword
        };

        if (verificationChecked.value) {
            verificationPayload = {
                additionalInformation: formDataPNV.additionalInformation,
                businessCity: formDataPNV.businessCity,
                businessContactEmail: formDataPNV.businessContactEmail,
                businessContactFirstName: formDataPNV.businessContactFirstName,
                businessContactLastName: formDataPNV.businessContactLastName,
                businessContactPhone: `1${formDataPNV.businessContactPhone}`, // 1 for country code
                businessCountry: formDataPNV.businessCountry,
                businessName: formData.schoolName,
                businessPostalCode: formDataPNV.businessPostalCode,
                businessStateProvinceRegion: formDataPNV.businessStateProvinceRegion,
                businessStreetAddress: formDataPNV.businessStreetAddress,
                businessWebsite: formDataPNV.businessWebsite,
                messageVolume: formDataPNV.messageVolume,
                notificationEmail,
                optInImageUrls: [formDataPNV.optInImageUrls],
                optInType,
                productionMessageSample,
                useCaseCategories,
                useCaseSummary
            };

            const isValidated = validateTwilioVerification(verificationPayload);
            if (!isValidated.status) {
                throw isValidated.message;
            }
        }

        if (!uploadFaqsCsvRef.value.faqs.length) {
            Alerts.alert({
                type: 'error',
                message: 'Please upload a valid FAQ CSV file.',
                timeout: 4000
            });
            showLoadingSpinner.value = false;
            return;
        }

        const response = await SchoolApi.createSchool({ payload });
        if (response) {
            logs.value = response.logs;
            Alerts.alert({
                type: 'success',
                message: 'School created!',
                timeout: 3000
            });
            isDashboardCreated.value = true;

            if (registerTwilioOnCreation === true) {
                await registerTwilioPhoneNumber(response.schoolId);
                if (verificationChecked.value) {
                    await submitTwilioPhoneVerification({
                        payload: {
                            ...verificationPayload,
                            schoolId: response.schoolId
                        }
                    });
                }
            }

            await uploadFaqsCsvRef.value.uploadFaqs(response.schoolId);
            await enableFeatureFlags(response.schoolId);
            resetForm();
        }
    } catch (error) {
        Alerts.alert({
            type: 'error',
            message: 'Could not create school.',
            timeout: 4000
        });
        console.error(error);
    } finally {
        showLoadingSpinner.value = false;
    }
}

async function enableFeatureFlags(schoolId) {
    try {
        await enableFeatureFlag(schoolId, featureFlagTypes.reactiveChatbotAdminUiFeature);
        Alerts.alert({
            type: 'success',
            message: 'Reactive chatbot feature flags enabled!',
            timeout: 4000
        });
    } catch (error) {
        Alerts.alert({
            type: 'error',
            message: 'Could not toggle feature flags.',
            timeout: 4000
        });
        console.error(error);
    }
}

async function registerTwilioPhoneNumber(schoolId) {
    try {
        const registrationResponse = await registerSubAccount({ payload: { schoolId } });
        if (registrationResponse.error) {
            logs.value.push(`Could not register sub account: ${registrationResponse.message}`);
            throw registrationResponse;
        }
        logs.value.push(`SubAccount created: ${registrationResponse.friendlyName}`);
    } catch (error) {
        Alerts.alert({
            type: 'error',
            message: 'Could not register sub account.',
            timeout: 4000
        });
        console.error(error.message);
    }
}

async function submitTwilioPhoneVerification({ payload }) {
    try {
        const verificationResponse = await submitPhoneVerification({ payload });
        if (verificationResponse.error) {
            logs.value.push(
                `Could not submit toll free verification: ${verificationResponse.message}`
            );
            throw verificationResponse;
        }
        logs.value.push('Toll Free Verification submitted successfully.');
    } catch (error) {
        Alerts.alert({
            type: 'error',
            message: 'Could not submit toll free verification.',
            timeout: 4000
        });
        console.error(error.message);
    }
}
</script>

<style lang="scss" scoped src="./styles/create-school-dashboard.scss"></style>
