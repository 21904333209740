<template>
    <div>
        <h2>Phone Number Verification Fields</h2>
        <div class="dashboard-container__form">
            <BaseInputText
                v-for="field in textFields"
                v-model="localFormData[field.key]"
                :key="field.key"
                :label="field.label"
                :placeholder="field.placeholder"
            />

            <BaseSelect
                v-model="localFormData.messageVolume"
                :options="messageVolumeList"
                @update:modelValue="volumeSelectHandler"
                label="Message Volume"
                placeholder="Choose a volume..."
            />

            <BaseTextarea
                v-model="localFormData.additionalInformation"
                label="Additional Info"
                placeholder="EdSights University, through www.edsights.io, adheres to legal texting standards..."
            />
        </div>
    </div>
</template>

<script setup>
// vue
import { reactive, defineProps, watch, defineEmits } from 'vue';

// ui-core
import { BaseInputText, BaseSelect, BaseTextarea } from '@edsights/ui-core';

// utils
import { phoneNumberVerificationFormTextFields as textFields } from './consts/textFields';

// styles
import './styles/create-school-dashboard.scss';

// props
const props = defineProps({
    formData: Object
});

// emits
const emit = defineEmits(['update:formData']);

// reactives
const localFormData = reactive({ ...props.formData });

// watch for changes in localFormData and emit updates
watch(
    localFormData,
    newVal => {
        emit('update:formData', newVal);
    },
    { deep: true }
);

// vars
const messageVolumeList = [
    { label: '1', value: '1' },
    { label: '10', value: '10' },
    { label: '1,000', value: '1,000' },
    { label: '10,000', value: '10,000' },
    { label: '100,000', value: '100,000' },
    { label: '250,000', value: '250,000' },
    { label: '500,000', value: '500,000' },
    { label: '750,000', value: '750,000' },
    { label: '1,000,000', value: '1,000,000' },
    { label: '5,000,000', value: '5,000,000' },
    { label: '10,000,000', value: '10,000,000' }
];

// functions
const volumeSelectHandler = selectedOption => {
    localFormData.messageVolume = selectedOption.value;
};
</script>
