// Vue core
import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';

// Third-party libraries
import { createTooltip, destroyTooltip } from 'floating-vue';
import { debounce } from 'lodash';

// Constants
import { CHATBOT_FLOW_STATUS } from '@/consts/chatbot-flow';

// Composables
import { useSchools } from './useSchools';

export function useFilters(labels = []) {
    // refs
    const filters = ref({
        schoolId: null,
        type: null,
        name: '',
        dateAfter: null,
        dateBefore: null,
        labels: [],
        tags: [],
        status: null
    });
    const tags = ref([]);
    const tooltipInstance = ref(null);

    // composables
    const {
        schoolsList,
        isSchoolsLoading,
        loadNextPage,
        searchSchools,
    } = useSchools();

    // Computed property to format tags into grouped options
    const formattedTags = computed(() => {
        // Group tags by categoryName
        const groupedTags = tags.value.reduce((acc, tag) => {
            if (!acc[tag.categoryName]) {
                acc[tag.categoryName] = [];
            }
            acc[tag.categoryName].push({
                label: tag.name,
                value: tag.id
            });
            return acc;
        }, {});

        // Convert to array format with children
        return Object.entries(groupedTags).map(([category, tags]) => ({
            label: category,
            children: tags
        }));
    });

    // computed
    const isTagsFilterDisabled = computed(() => !formattedTags.value.length);

    const filterOptions = computed(() => [
        {
            id: 'schoolId',
            type: 'select',
            label: 'Select school',
            hideLabel: true,
            placeholder: 'Select school',
            options: schoolsList.value.list,
            optionLabel: 'name',
            optionValue: 'id',
            onLoadOptionsNextPage: loadNextPage,
            asyncFilter: true,
            optionsLoading: isSchoolsLoading.value
        },
        {
            id: 'type',
            type: 'select',
            label: 'Select Type',
            hideLabel: true,
            placeholder: 'Select Type',
            options: [
                { label: 'All touchpoints', value: null },
                { label: 'Custom touchpoints', value: 'Custom' },
                { label: 'EdSights touchpoints', value: 'EdSights' }
            ]
        },
        {
            id: 'name',
            type: 'text',
            label: 'Search by Flow Name',
            hideLabel: true,
            placeholder: 'Search by Flow Name'
        },
        {
            id: 'dateAfter',
            type: 'date',
            label: 'Select Date After',
            hideLabel: true,
            placeholder: 'Select Date After'
        },
        {
            id: 'dateBefore',
            type: 'date',
            label: 'Select Date Before',
            hideLabel: true,
            placeholder: 'Select Date Before'
        },
        {
            id: 'labels',
            type: 'multiselect',
            label: 'Select Labels',
            hideLabel: true,
            placeholder: 'Select Labels',
            options: labels.map(label => ({
                label: label.text,
                value: label.id
            })),
            display: 'comma',
            filter: true
        },
        {
            id: 'tags',
            type: 'multiselect',
            disabled: isTagsFilterDisabled.value,
            label: 'Select Tags',
            hideLabel: true,
            placeholder: 'Select Tags',
            options: formattedTags.value,
            display: 'comma',
            filter: true,
            group: true,
            optionGroupLabel: 'label',
            optionGroupChildren: 'children'
        },
        {
            id: 'status',
            type: 'select',
            label: 'Select Status',
            hideLabel: true,
            placeholder: 'Select Status',
            options: [
                CHATBOT_FLOW_STATUS.SCHEDULED,
                CHATBOT_FLOW_STATUS.INITIATED,
                CHATBOT_FLOW_STATUS.DRAFT
            ].map(status => ({
                label: status.label,
                value: status.value
            }))
        }
    ]);

    // functions
    const updateTooltip = isDisabled => {
        const tagsFilter = document.getElementById('tags');
        if (tagsFilter) {
            if (isDisabled && !tooltipInstance.value) {
                tooltipInstance.value = createTooltip(tagsFilter, {
                    content: 'Please select a school first',
                    placement: 'top'
                });
            } else if (!isDisabled && tooltipInstance.value) {
                destroyTooltip(tagsFilter);
                tooltipInstance.value = null;
            }
        }
    };

    const handleAsyncFilter = debounce(async ({ filterId, value }) => {
        if (filterId === 'schoolId') {
            await searchSchools(value);
        }
    }, 300);

    // watchers
    watch(
        () => isTagsFilterDisabled.value,
        isDisabled => {
            updateTooltip(isDisabled);
        }
    );

    // Mount tooltip if tags are disabled initially
    onMounted(() => {
        nextTick(() => {
            updateTooltip(!filterOptions.value.find(opt => opt.id === 'tags').options.length);
        });
    });

    // Clean up tooltip on component unmount
    onBeforeUnmount(() => {
        const tagsFilter = document.getElementById('tags');
        if (tagsFilter) {
            destroyTooltip(tagsFilter);
        }
    });

    return {
        filters,
        filterOptions,
        handleAsyncFilter,
        updateTooltip,
        schoolsList,
        tags
    };
} 
