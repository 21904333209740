<template>
    <BaseFileUpload accept=".csv" label="Choose CSV file" @onSelect="handleFaqCsvUpload" />
</template>

<script setup>
// vue
import { computed, defineExpose, onMounted, ref } from 'vue';

// libs
import Alerts from '@/lib/alerts';
import Papa from 'papaparse';

// api
import * as FAQTopicsApi from '@/api/faq-topics';
import * as FAQv2Api from '@/api/faqs-v2';

// ui-core
import { BaseFileUpload } from '@edsights/ui-core';

// refs
const faqs = ref([]);
const faqTopics = ref([]);

// computed
const faqSubtopics = computed(() =>
    faqTopics.value
        .map(topic => {
            return topic.subtopics.map(subtopic => ({
                name: subtopic.name.trim(),
                id: subtopic.id
            }));
        })
        .flat()
);

// functions
function handleFaqCsvUpload([file]) {
    if (file && file.type !== 'text/csv') {
        Alerts.alert({
            type: 'error',
            message: 'Please upload a valid CSV file.',
            timeout: 4000
        });
        return;
    }

    Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function(results) {
            const expectedHeaders = ['topic', 'subtopic', 'questionText', 'answerText'];
            const actualHeaders = results.meta.fields;

            const headersAreCorrect =
                expectedHeaders.length === actualHeaders.length &&
                expectedHeaders.every((header, index) => header === actualHeaders[index]);

            if (!headersAreCorrect) {
                Alerts.alert({
                    type: 'error',
                    message: `CSV file does not have the correct column headers in the correct order. Expected column headers: ${expectedHeaders.join(
                        ', '
                    )}`,
                    timeout: 4000
                });
                return;
            }

            if (!results.data.length) {
                Alerts.alert({
                    type: 'error',
                    message: 'No data found in CSV file.',
                    timeout: 4000
                });
                return;
            }

            faqs.value = parseTopics(results.data);
        },
        error: function() {
            Alerts.alert({
                type: 'error',
                message: 'Error parsing CSV file.',
                timeout: 4000
            });
        }
    });
}

function parseTopics(faqArrayFromCsv) {
    const invalidTopics = [];
    const invalidSubtopics = [];
    let output = [];

    output = faqArrayFromCsv.map(({ topic, subtopic, questionText, answerText }) => {
        const topicId = faqTopics.value.find(
            faqTopic => faqTopic.name.toLowerCase() === topic.toLowerCase()
        )?.id;
        const subtopicId = faqSubtopics.value.find(
            sub => sub.name.toLowerCase() === subtopic.toLowerCase()
        )?.id;

        if (!topicId) {
            invalidTopics.push(topic);
        }
        if (!subtopicId) {
            invalidSubtopics.push(subtopic);
        }

        return {
            answerText,
            questionText,
            subtopic,
            subtopicId,
            topic,
            topicId
        };
    });

    if (invalidTopics.length) {
        Alerts.alert({
            type: 'error',
            message: `Invalid topics: ${invalidTopics.join(', ')}`,
            timeout: 4000
        });

        return;
    }
    if (invalidSubtopics.length) {
        Alerts.alert({
            type: 'error',
            message: `Invalid subtopics: ${invalidSubtopics.join(', ')}`,
            timeout: 4000
        });
        return;
    }

    return output;
}

// async functions
async function uploadFaqs(schoolId) {
    if (faqs.value.length) {
        try {
            await FAQv2Api.upsertFaqs({
                faqs: faqs.value,
                schoolId
            });
            Alerts.alert({
                type: 'success',
                message: 'FAQ entries uploaded!',
                timeout: 4000
            });
        } catch (error) {
            Alerts.alert({
                type: 'error',
                message: 'Could not upload FAQ entries.',
                timeout: 4000
            });
            console.error(error);
        }
        faqs.value = [];
    }
}

// lifecycle
onMounted(async () => {
    faqTopics.value = await FAQTopicsApi.listFaqTopics();
});

// exposes
defineExpose({ uploadFaqs, faqs });
</script>
