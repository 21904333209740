<!-- 
    @deprecated 
    This component has been refactored and split into multiple parts:
    1. UI/Presentation: Moved to FlowsV2/components/FlowsViewTable/columns/NameColumn.vue
    2. Business Logic: Moved to FlowsV2/composables/useTemplateCreation.js
    3. Modal: Moved to FlowsViewTable.vue
    
    This component can be safely removed once FlowsV2 is fully deployed.
    
    The new architecture separates concerns:
    - The NameColumn handles only the UI and emits events
    - useTemplateCreation handles the template creation logic
    - FlowsViewTable manages the modal state and orchestrates the flow
-->
<template>
    <div class="create-reusable-template-from-chatbot-flow-button-container">
        <div class="tooltip-container" @click="openConfirmationModal">
            <img
                v-tooltip="'Create Template'"
                class="tooltip-image"
                src="@/assets/icons/copy-file.svg"
                @click="openConfirmationModal"
                alt="Copy file"
            />
        </div>
        <BaseModal
            v-if="showConfirmationModal"
            header="Create reusable template"
            actionText="Confirm"
            :style="{ width: '30rem' }"
            :loading="loading"
            :showActions="true"
            @close="closeConfirmationModal"
            @handleAction="copy"
        >
            <div class="container">
                Are you sure you want to create a reusable template from this flow?
            </div>
        </BaseModal>
    </div>
</template>

<script>
import { copyChatbotFlow } from '@/api/chatbot-flow';
import { BaseModal } from '@edsights/ui-core';
import { CHATBOT_FLOW_STATUS, DRAFT_NULL_DATE_PLACEHOLDER } from '@/consts/chatbot-flow';

export default {
    name: 'CreateReusableTemplateFromChatbotFlowButton',
    components: { BaseModal },
    props: {
        chatbotFlowId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            showConfirmationModal: false
        };
    },
    methods: {
        openConfirmationModal() {
            this.showConfirmationModal = true;
        },
        closeConfirmationModal() {
            this.showConfirmationModal = false;
        },
        async copy() {
            try {
                this.loading = true;
                await copyChatbotFlow({
                    id: this.chatbotFlowId,
                    // Set the flow's status to "CANCELED" so that the flow doesn't show up on the FLows page,
                    // Use the null date placeholder, since we don't want to save an actual date on these reusable templates.
                    // Set template.reusable to true so that it shows up on the Templates page.
                    overrides: {
                        status: CHATBOT_FLOW_STATUS.CANCELED.value,
                        date: DRAFT_NULL_DATE_PLACEHOLDER,
                        template: {
                            reusable: true
                        }
                    },
                    errorHandlerOptions: { rethrow: true, enableAlert: true }
                });

                this.$Alert.alert({
                    type: 'success',
                    message: 'Template created.',
                    timeout: 10000
                });
            } finally {
                this.loading = false;
                this.closeConfirmationModal();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.create-reusable-template-from-chatbot-flow-button-container {
    display: flex;
    margin-bottom: 1px;
    cursor: pointer;
    .tooltip-image {
        width: 12px;
    }
}
</style>
