/**
 * Composable for managing chatbot flow template creation functionality.
 * 
 * This composable handles:
 * 1. Modal state management for template creation confirmation
 * 2. Template creation process including API calls
 * 3. Success/error notifications
 * 
 * The template creation process:
 * 1. Creates a copy of an existing flow
 * 2. Sets it as a reusable template
 * 3. Marks it as canceled to hide from flows list
 * 4. Uses null date placeholder for templates
 */

import { ref } from 'vue';
import { copyChatbotFlow } from '@/api/chatbot-flow';
import { CHATBOT_FLOW_STATUS, DRAFT_NULL_DATE_PLACEHOLDER } from '@/consts/chatbot-flow';
import Alerts from '@/lib/alerts';

export function useTemplateCreation() {
    const showCreateTemplateModal = ref(false);
    const creatingTemplate = ref(false);
    const selectedFlowId = ref(null);

    const openCreateTemplateModal = (flowId) => {
        selectedFlowId.value = flowId;
        showCreateTemplateModal.value = true;
    };

    const closeCreateTemplateModal = () => {
        showCreateTemplateModal.value = false;
        selectedFlowId.value = null;
    };

    const createTemplate = async () => {
        if (!selectedFlowId.value) return;

        try {
            creatingTemplate.value = true;
            await copyChatbotFlow({
                id: selectedFlowId.value,
                overrides: {
                    status: CHATBOT_FLOW_STATUS.CANCELED.value,
                    date: DRAFT_NULL_DATE_PLACEHOLDER,
                    template: {
                        reusable: true
                    }
                },
                errorHandlerOptions: { rethrow: true, enableAlert: true }
            });

            Alerts.alert({
                type: 'success',
                message: 'Template created.',
                timeout: 10000
            });
        } finally {
            creatingTemplate.value = false;
            closeCreateTemplateModal();
        }
    };

    return {
        showCreateTemplateModal,
        creatingTemplate,
        selectedFlowId,
        openCreateTemplateModal,
        closeCreateTemplateModal,
        createTemplate
    };
} 
