export const createSchoolFormTextFields = [
    {
        label: 'School Name',
        key: 'schoolName',
        placeholder: 'EdSights College'
    },
    {
        label: 'School Nickname',
        key: 'schoolNickname',
        placeholder: 'EdSights'
    },
    {
        label: 'School Mascot Name',
        key: 'schoolMascotName',
        placeholder: 'Mascot'
    },
    {
        label: 'Admin First Name',
        key: 'adminFirstName',
        placeholder: 'John'
    },
    {
        label: 'Admin Last Name',
        key: 'adminLastName',
        placeholder: 'Doe'
    },
    {
        label: 'Admin Email',
        key: 'adminEmail',
        placeholder: 'JohnDoe@edsights.edu'
    },
    {
        label: 'Temporary Password',
        key: 'temporaryPassword'
    }
];

export const phoneNumberVerificationFormTextFields = [
    {
        label: 'Street Address',
        key: 'businessStreetAddress',
        placeholder: '1 Main Street'
    },
    {
        label: 'City',
        key: 'businessCity',
        placeholder: 'New York'
    },
    {
        label: 'State',
        key: 'businessStateProvinceRegion',
        placeholder: 'NY'
    },
    {
        label: 'Postal Code',
        key: 'businessPostalCode',
        placeholder: '55555'
    },
    {
        label: 'Contact First Name',
        key: 'businessContactFirstName',
        placeholder: 'John'
    },
    {
        label: 'Contact Last Name',
        key: 'businessContactLastName',
        placeholder: 'Doe'
    },
    {
        label: 'Contact Email',
        key: 'businessContactEmail',
        placeholder: 'JohnDoe@edsights.edu'
    },
    {
        label: 'Contact Phone #',
        key: 'businessContactPhone',
        placeholder: '1234567890'
    },
    {
        label: 'Website',
        key: 'businessWebsite',
        placeholder: 'www.edsights.io'
    },
    {
        label: 'Opt In Image Url',
        key: 'optInImageUrls',
        placeholder: 'https://drive.google.com/file/d/1gAq9g'
    }
];
