<template>
    <div class="type-content">
        <TouchpointIcon
            v-if="props.touchpointRef"
            :is-custom="props.touchpointRef.isCustom"
            :alt="props.touchpointRef.isCustom 
                ? 'Feather representing a custom touchpoint'
                : 'EdSights logo representing a non-custom touchpoint'"
        />
    </div>
</template>

<script setup>
import { TouchpointIcon } from '@/views/staff/ChatbotFlowList/components/FlowsV2/components';

const props = defineProps({
    touchpointRef: {
        type: Object,
        required: true
    }
});
</script>

<style lang="scss" scoped>
.type-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        height: 40px;
        width: 40px;
    }
}
</style> 
