<template>
    <div class="flows-view-table">
        <BaseTable
            :value="tableData"
            :columns="baseTableColumns"
            :loading="loading"
            :loadingNextPage="loadingNextPage"
            :displayLoadMoreButton="displayLoadMoreButton"
            empty-state-message="No results."
            :empty-state-sub-message="emptyStateSubMessage"
            @lazy-load="onLazyLoad"
        >
            <template #typeColumn="{ data }">
                <TypeColumn :touchpoint-ref="data.touchpointRef" />
            </template>

            <template #nameColumn="{ data }">
                <NameColumn
                    :id="data.id"
                    :name="data.name"
                    :touchpoint-description="data.touchpointRef?.description"
                />
            </template>

            <template #dateColumn="{ data }">
                <DateColumn
                    :id="data.id"
                    :date="data.date"
                    :status="data.status"
                    @flow-date-updated="onFlowDateUpdated"
                />
            </template>

            <template #labelsColumn="{ data }">
                <LabelsColumn :labels="data.labels" />
            </template>

            <template #tagsColumn="{ data }">
                <TagsColumn :tags="data.tags" />
            </template>

            <template #statusColumn="{ data }">
                <StatusColumn :status="data.status" @toggle-status="() => toggleStatus(data)" />
            </template>

            <template #actionsColumn="{ data }">
                <ActionsColumn
                    :id="data.id"
                    :status="data.status"
                    :historical="data.historical"
                    @open-cancel-flow-modal="openCancelFlowModal"
                    @create-template="openCreateTemplateModal"
                />
            </template>

            <template #expansion="{ data }">
                <FlowSchoolInfo :flowId="data.id" />
            </template>
        </BaseTable>

        <!-- Template creation modal -->
        <BaseModal
            v-if="showCreateTemplateModal"
            header="Create reusable template"
            actionText="Confirm"
            :style="{ width: '30rem' }"
            :loading="creatingTemplate"
            :showActions="true"
            @close="closeCreateTemplateModal"
            @handleAction="createTemplate"
        >
            <div class="container">
                Are you sure you want to create a reusable template from this flow?
            </div>
        </BaseModal>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { BaseTable, BaseModal } from '@edsights/ui-core';
import FlowSchoolInfo from './FlowSchoolInfo/FlowSchoolInfo.vue';
import { useTemplateCreation } from '@/views/staff/ChatbotFlowList/components/FlowsV2/composables/useTemplateCreation';

// Import column components
import {
    TypeColumn,
    NameColumn,
    DateColumn,
    LabelsColumn,
    TagsColumn,
    StatusColumn,
    ActionsColumn
} from './columns';

const props = defineProps({
    tableData: {
        type: Array,
        required: true
    },
    loading: {
        type: Boolean,
        default: false
    },
    loadingNextPage: {
        type: Boolean,
        default: false
    },
    selectedRowId: {
        type: String,
        default: null
    },
    displayLoadMoreButton: {
        type: Boolean,
        default: true
    },
    hasActiveFilters: {
        type: Boolean,
        default: false
    }
});

const emptyStateSubMessage = computed(() =>
    props.hasActiveFilters
        ? 'No flows match your current filters. Try adjusting your filters to see more results.'
        : 'There are no flows currently in the system.'
);

const emit = defineEmits([
    'toggle-selected-row',
    'open-cancel-flow-modal',
    'toggle-status',
    'flow-date-updated',
    'lazy-load'
]);

const {
    showCreateTemplateModal,
    creatingTemplate,
    openCreateTemplateModal,
    closeCreateTemplateModal,
    createTemplate
} = useTemplateCreation();

const baseTableColumns = computed(() => [
    { field: 'type', header: 'Type', slotName: 'typeColumn' },
    { field: 'name', header: 'Flow Name', slotName: 'nameColumn' },
    { field: 'date', header: 'Scheduled Date', slotName: 'dateColumn' },
    { field: 'labels', header: 'Labels', slotName: 'labelsColumn' },
    { field: 'tags', header: 'Tags', slotName: 'tagsColumn' },
    { field: 'status', header: 'Status', slotName: 'statusColumn' },
    { field: 'actions', header: 'Actions', slotName: 'actionsColumn' }
]);

const openCancelFlowModal = flowId => {
    emit('open-cancel-flow-modal', flowId);
};

const toggleStatus = flow => {
    emit('toggle-status', flow);
};

const onFlowDateUpdated = flow => {
    emit('flow-date-updated', flow);
};

const onLazyLoad = () => {
    emit('lazy-load');
};
</script>

<style lang="scss" scoped>
.flows-view-table {
    :deep(.ui-core-base-table) {
        .p-datatable-column-header-content {
            white-space: nowrap;
        }
    }
}
</style>
