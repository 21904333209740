<template>
    <div class="student-voice-score-over-time-card-container">
        <BaseCard>
            <template #content>
                <div class="student-voice-score-over-time-card-container-header">
                    <h2 class="student-voice-score-over-time-card-container__title">
                        Score Over Time
                        <BaseIcon
                            icon="questioncircle"
                            class="student-voice-score-over-time-card-container__title__helper-icon"
                            v-tooltip="'The history of your school\'s SVS'"
                        />
                    </h2>
                    <div class="student-voice-score-over-time-card-container-subtitle">
                        View your historical SVS trend
                    </div>
                </div>
                <BaseDivider />
                <div class="student-voice-score-over-time-card-content">
                    <div class="score-difference" v-if="scoreDifference">
                        <div class="difference" :class="scoreDifference.class">
                            <i
                                class="difference-icon pi"
                                :class="
                                    scoreDifference.difference > 0
                                        ? 'pi-arrow-circle-up'
                                        : scoreDifference.difference < 0
                                        ? 'pi-arrow-circle-down'
                                        : ''
                                "
                            />
                            <span>{{ scoreDifference.difference + ' ' }}</span>
                        </div>
                        <span class="date">since {{ scoreDifference.sinceDate }}</span>
                    </div>
                    <LineChart
                        class="chart"
                        :height="230"
                        :chartData="chartData"
                        :yAxisTickLimit="3"
                        :yAxisMin="-100"
                        :yAxisMax="100"
                        xAxisType="time"
                        xAxisTimeUnit="year"
                        :xAxisMin="chartTimeAxisLabels.min"
                        :xAxisMax="chartTimeAxisLabels.max"
                        :ariaLabel="chartAriaLabel"
                        :tooltipCallbacks="tooltipCallbacks"
                    />
                </div>
            </template>
        </BaseCard>
    </div>
</template>

<script setup>
import LineChart from '@/components-deprecated/charts/LineChart';
import { formatISO, FORMATS } from '@/lib/dates';
import { getDefaultLineChartProperties } from '@/components-deprecated/charts/constants';
import { DateTime } from 'luxon';
import { computed, defineProps } from 'vue';
import { BaseCard, BaseDivider, BaseIcon } from '@edsights/ui-core';

// define props
const props = defineProps({
    studentVoiceScores: {
        type: Array,
        required: false
    }
});

// define methods
const formatDifference = difference => {
    if (typeof difference === 'number') {
        return (difference >= 0 ? '+' : '') + difference;
    }

    return null;
};

const getDifferenceClass = difference => {
    if (typeof difference === 'number') {
        return difference > 0 ? 'increase' : difference < 0 ? 'decrease' : '';
    }

    return null;
};

// define computed properties
const scoreDifference = computed(() => {
    if (Array.isArray(props.studentVoiceScores) && props.studentVoiceScores.length > 1) {
        const currentIndex = props.studentVoiceScores.length - 1;
        const current = props.studentVoiceScores[currentIndex];
        const previous = props.studentVoiceScores[currentIndex - 1];
        const difference = current.score - previous.score;

        return {
            difference: formatDifference(difference),
            class: getDifferenceClass(difference),
            sinceDate: formatISO(previous.chatbotFlowDate, FORMATS.MONTH_LONG_YEAR_LONG)
        };
    }

    return null;
});

const chartData = computed(() => {
    const data = [];

    if (Array.isArray(props.studentVoiceScores)) {
        props.studentVoiceScores.forEach(studentVoiceScore => {
            data.push({
                // this x value must be a luxon date object, or an iso string that matches the
                // xAxisTimeParser provided to LineChart
                x: DateTime.fromISO(studentVoiceScore.chatbotFlowDate),
                y: studentVoiceScore.score
            });
        });
    }

    return {
        datasets: [
            {
                data,
                ...getDefaultLineChartProperties()
            }
        ]
    };
});

const chartAriaLabel = computed(() => {
    const title = 'SVS score over time: ';
    if (Array.isArray(props.studentVoiceScores) && props.studentVoiceScores.length) {
        const length = props.studentVoiceScores.length;
        const dataString = props.studentVoiceScores.reduce((acc, svs, index) => {
            const time = formatISO(svs.chatbotFlowDate, FORMATS.MONTH_LONG_YEAR_LONG);
            const score = svs.score;
            return `${acc}${time}: ${score}${index === length - 1 ? '.' : ', '}`;
        }, '');
        return `${title} ${dataString}`;
    }

    return null;
});

const chartTimeAxisLabels = computed(() => {
    if (Array.isArray(props.studentVoiceScores)) {
        // Note: This card only displays when there is at least one score, so there is
        // guaranteed to be at least one in the array.
        const firstSvs = props.studentVoiceScores[0];
        const lastSvs = props.studentVoiceScores[props.studentVoiceScores.length - 1];
        return {
            min: DateTime.fromISO(firstSvs.chatbotFlowDate)
                .startOf('year')
                .toISO(),
            max: DateTime.fromISO(lastSvs.chatbotFlowDate)
                .plus({ years: 1 })
                .startOf('year')
                .toISO()
        };
    }

    return null;
});

const tooltipCallbacks = computed(() => {
    return {
        title: function(tooltipItem) {
            // Get x value and confirm it's a luxon date
            // (chart.js is using luxon as a date parser)
            if (
                Array.isArray(tooltipItem) &&
                tooltipItem[0] &&
                tooltipItem[0].raw &&
                tooltipItem[0].raw.x &&
                //check for .toISO luxon method
                tooltipItem[0].raw.x.toISO
            ) {
                return formatISO(tooltipItem[0].raw.x.toISO(), FORMATS.MONTH_LONG_YEAR_LONG);
            }
            return '';
        },
        label: function(context) {
            if (context && context.formattedValue) {
                return `${context.formattedValue} SVS`;
            }
            return '';
        }
    };
});
</script>

<style lang="scss" scoped>
@import './student-voice-score-over-time-card.scss';
</style>
