<template>
    <div class="gauge-container">
        <Chart
            type="doughnut"
            :data="chartData"
            :options="chartOptions"
            style="width: 50px; height: 50px;"
        />
        <div class="gauge-desc">
            <b>
                {{ totalFeedback }}
            </b>
            of {{ totalStudents }} {{ props.studentType }} provided additional feedback
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from 'vue';
import Chart from 'primevue/chart';

const totalStudents = computed(() => {
    if (props.totalStudents < 1) {
        return 0;
    }
    return props.totalStudents;
});

const totalFeedback = computed(() => {
    if (props.totalStudents < 1) {
        return 0;
    }
    return props.totalFeedback;
});

const props = defineProps({
    totalFeedback: {
        type: Number,
        required: true
    },
    totalStudents: {
        type: Number,
        required: true
    },
    color: {
        type: String,
        required: true
    },
    emptyColor: {
        type: String,
        required: true
    },
    studentType: {
        type: String,
        required: true
    }
});

onMounted(() => {
    chartData.value = setChartData();
    chartOptions.value = setChartOptions();
});

const chartData = ref();
const chartOptions = ref(null);

const setChartData = () => {
    return {
        datasets: [
            {
                data: [totalFeedback.value, totalStudents.value - totalFeedback.value],
                backgroundColor: [props.color, props.emptyColor]
            }
        ]
    };
};

const setChartOptions = () => {
    return {
        cutout: '65%',
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: false
            }
        }
    };
};
</script>

<style scoped lang="scss">
.gauge-container {
    display: flex;
    align-items: center;
}

.gauge-desc {
    margin-left: 20px;
}
</style>
