<template>
    <div class="student-voice-score-summary-container">
        <BaseCard>
            <template #content>
                <div class="header-content student-voice-score-summary-container-header">
                    <h2 class="student-voice-score-summary-container__title">
                        {{ title }}
                        <BaseIcon
                            icon="questioncircle"
                            class="student-voice-score-summary-container__title__helper-icon"
                            v-tooltip="titleTooltip"
                        />
                    </h2>
                    <div class="student-voice-score-summary-container-subtitle">
                        Key themes from student feedback
                    </div>
                </div>
                <BaseDivider />
                <div class="student-voice-score-summary-content">
                    <StudentFeedback
                        v-for="[studentType, feedback] in feedbackEntries"
                        :key="studentType"
                        :studentType="studentType"
                        :feedback="feedback"
                        :studentCount="getCountForStudentType(studentType)"
                        :chatbotFlowId="studentVoiceScore.chatbotFlowId"
                    />

                    <EmptyState
                        v-if="isEmpty"
                        icon="#icon-svs-chart"
                        message="Nothing to see here yet"
                        sub-message="Stay tuned - more SVS insights are coming soon."
                    />
                </div>
            </template>
        </BaseCard>
    </div>
</template>

<script setup>
import { BaseCard, BaseDivider, BaseIcon } from '@edsights/ui-core';
import StudentFeedback from './components/StudentFeedback.vue';
import { computed, defineProps } from 'vue';
import EmptyState from '@/components-deprecated/global/EmptyState.vue';

const studentTypeOrder = ['PROMOTER', 'PASSIVE', 'DETRACTOR'];

//props
const props = defineProps({
    title: {
        type: String,
        default: 'Student Voice Summary'
    },
    titleTooltip: {
        type: String,
        default: "Your school's latest SVS Summary"
    },
    studentVoiceSummary: {
        type: Object,
        default: () => ({})
    },
    studentVoiceScore: {
        type: Object,
        default: () => ({})
    }
});

// computed
const feedbackEntries = computed(() => {
    return Object.entries(props.studentVoiceSummary || {}).sort((a, b) => {
        const studentTypeA = a[0];
        const studentTypeB = b[0];

        return (
            studentTypeOrder.indexOf(studentTypeA) - studentTypeOrder.indexOf(studentTypeB)
        );
    });
});

const isEmpty = computed(() => {
    return Object.entries(feedbackEntries.value).length === 0;
});

// methods
const getCountForStudentType = studentType => {
    const key = studentType.toLowerCase() + 'Count';
    return props.studentVoiceScore[key] || 0;
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.student-voice-score-summary-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__title {
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;

        &__helper-icon {
            padding-left: 0.5rem;
        }
    }

    .student-voice-score-summary-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .header-content {
        display: flex;
        flex-direction: column;
        gap: var(--space-sm);
    }
    .header-content h2 {
        color: var(--color-text);
        display: flex;
        gap: var(--space-sm);
        align-items: center;
        margin: 0;
    }
}
.student-voice-score-summary-content > * {
    margin: 15px;
}
</style>
