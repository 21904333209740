<template>
    <div class="flows-header">
        <div class="header-content">
            <div class="description">
                <h2>
                    Flows
                    <BaseLoading v-if="isApplyingFilters" />
                </h2>
                <p>
                    These are all the flows drafted and scheduled. You can filter by school, type,
                    name, date range, labels, tags and status.
                </p>
            </div>
            <div class="create-new-button">
                <BaseButton label="New" @click="goToCreateNew" />
            </div>
        </div>
    </div>
</template>

<script setup>
// Router
import { useRouter } from 'vue-router';

// UI Components
import { BaseButton, BaseLoading } from '@edsights/ui-core';

// Router
const router = useRouter();

// Props
const props = defineProps({
    isApplyingFilters: {
        type: Boolean,
        default: false
    }
});

// Methods
const goToCreateNew = () => {
    router.push({
        name: 'ChatbotFlowBuilderCreate'
    });
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.flows-header {
    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .description {
        margin: 10px 0;
        h2 {
            display: flex;
            align-items: center;
            gap: 2.5rem;
        }
    }
}
</style>
