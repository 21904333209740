<template>
    <div>
        <InlineScoreProgress
            v-if="showInline"
            :score="score"
            :comparedScore="comparedScore"
            :showScoreGradient="showScoreGradient"
            :renderedComparedScore="renderedComparedScore"
            :progressBarClass="getProgressBarClass"
            :progressBarData="progressBarData"
            :studentsCount="studentsCount"
            :scoreProgressLabel="scoreProgressLabel"
        />
        <CompleteScoreProgress
            v-else
            :score="score"
            :comparedScore="comparedScore"
            :showScoreGradient="showScoreGradient"
            :renderedComparedScore="renderedComparedScore"
            :progressBarClass="getProgressBarClass"
            :progressBarData="progressBarData"
        />
    </div>
</template>

<script>
import InlineScoreProgress from '@/views/StudentVoiceScorePage/components/components/ScoreProgress/components/InlineScoreProgress/InlineScoreProgress.vue';
import CompleteScoreProgress from '@/views/StudentVoiceScorePage/components/components/ScoreProgress/components/CompleteScoreProgress/CompleteScoreProgress.vue';

export default {
    name: 'ScoreProgress',
    components: { CompleteScoreProgress, InlineScoreProgress },
    props: {
        score: {
            type: Number,
            required: false
        },
        comparedScore: {
            type: Number,
            required: false
        },
        comparedScoreLabel: {
            type: String,
            default: 'Average score'
        },
        differenceLabelPrefix: {
            type: String,
            required: false
        },
        // Pass a custom suffix, otherwise the comparedScoreLabel is used as default.
        differenceLabelSuffix: {
            type: String,
            required: false
        },
        // Pass true to calculate rendered difference from compared score (comparedScore - score),
        // rather than the default (score - comparedScore).
        invertDifference: {
            type: Boolean,
            default: false
        },
        // Pass true to show score with a gradient background, colored based on the score's relative
        // value to the comparedScore. Otherwise, static default coloring is used.
        showScoreGradient: {
            type: Boolean,
            default: false
        },
        // Pass true to color the bar based on the score's relative value to
        // the comparedScore. Otherwise, static default coloring is used.
        showProgressBarColors: {
            type: Boolean,
            default: false
        },
        showInline: {
            type: Boolean,
            default: false
        },
        studentsCount: {
            type: Number,
            default: 0
        },
        scoreProgressLabel: {
            type: String,
            required: false
        },
        emptyStateMessage: {
            type: String,
            default: null
        }
    },
    methods: {
        // ProgressBar works with percentages (empty is 0, full is 100).
        // Translate the score so that a -100 score results in 0%, and +100 score in 100%.
        getProgressBarPercentFromScore(score) {
            const svsMin = -100;
            const svsMax = 100;
            return typeof score === 'number' &&
                // Score must be between or equal to the expected min and max SVS.
                score >= svsMin &&
                score <= svsMax &&
                // Confirm we don't end up with a denominator of 0. Should be impossible, but just
                // in case the hardcoded svsMin and svsMax above are changed.
                svsMax - svsMin !== 0
                ? ((score - svsMin) / (svsMax - svsMin)) * 100
                : null;
        },
        getDifference() {
            return this.invertDifference
                ? this.comparedScore - this.score
                : this.score - this.comparedScore;
        }
    },
    computed: {
        renderedComparedScore() {
            if (
                typeof this.score === 'number' &&
                typeof this.comparedScore === 'number' &&
                this.comparedScoreLabel &&
                typeof this.comparedScoreLabel === 'string'
            ) {
                const difference = this.getDifference();
                let differenceAbsoluteValue = Math.abs(difference);

                return {
                    differenceLabel:
                        difference === 0
                            ? `Equal to`
                            : `${ Math.round(differenceAbsoluteValue) } ${difference > 0 ? 'above' : 'below'}`,
                    differenceClass:
                        difference === 0 ? null : `${difference > 0 ? 'above' : 'below'}`,
                    comparedScoreLabel: this.comparedScoreLabel,
                    differenceLabelPrefix: this.differenceLabelPrefix,
                    differenceLabelSuffix: this.differenceLabelSuffix || this.comparedScoreLabel
                };
            }

            return null;
        },
        progressBarData() {
            const percentages = {
                percent: 0,
                markerPercent: 0,
                markerLabel: null
            };

            if (typeof this.score === 'number') {
                percentages.percent = this.getProgressBarPercentFromScore(this.score);
            }

            if (typeof this.comparedScore === 'number') {
                percentages.markerPercent = this.getProgressBarPercentFromScore(this.comparedScore);
                percentages.markerLabel = `${this.comparedScoreLabel} (${this.comparedScore.toFixed(
                    0
                )})`;
            }

            if (typeof this.comparedScore !== 'number' && !this.comparedScore) {
                percentages.markerLabel = this.emptyStateMessage;
            }

            return percentages;
        },
        getProgressBarClass() {
            if (
                this.showProgressBarColors &&
                typeof this.score === 'number' &&
                typeof this.comparedScore === 'number'
            ) {
                const difference = this.score - this.comparedScore;
                if (difference > 0) {
                    return 'greater-than';
                } else if (difference < 0) {
                    return 'less-than';
                }
            }
            return '';
        }
    }
};
</script>

<style scoped lang="scss"></style>
