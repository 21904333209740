// Vue core
import { computed } from 'vue';

// Constants
import { CHATBOT_FLOW_STATUS } from '@/consts/chatbot-flow';

export function useFilterChips(filters, schoolsList, tags, labels) {
    // Helper functions
    const formatDate = date => {
        if (!date) return '';
        return new Date(date).toLocaleDateString();
    };

    const filterChips = computed(() => {
        const chips = [];

        if (filters.value.schoolId) {
            const selectedSchool = schoolsList.value.list.find(s => s.id === filters.value.schoolId);
            if (selectedSchool) {
                chips.push({
                    label: `School: ${selectedSchool.name}`,
                    value: selectedSchool.id,
                    filterType: 'schoolId'
                });
            }
        }

        if (filters.value.type) {
            chips.push({
                label: `Type: ${filters.value.type}`,
                value: filters.value.type,
                filterType: 'type'
            });
        }

        if (filters.value.dateAfter) {
            chips.push({
                label: `Date Scheduled After: ${formatDate(filters.value.dateAfter)}`,
                value: filters.value.dateAfter,
                filterType: 'dateAfter'
            });
        }

        if (filters.value.dateBefore) {
            chips.push({
                label: `Date Scheduled Before: ${formatDate(filters.value.dateBefore)}`,
                value: filters.value.dateBefore,
                filterType: 'dateBefore'
            });
        }

        if (Array.isArray(filters.value.labels)) {
            filters.value.labels.forEach(labelId => {
                const label = labels.find(l => l.id === labelId);
                if (label) {
                    const { id, text } = label;
                    chips.push({ label: `Label: ${text}`, value: id, filterType: 'labels' });
                }
            });
        }

        if (filters.value.status) {
            const { label, value } = CHATBOT_FLOW_STATUS[filters.value.status];
            chips.push({ label: `Status: ${label}`, value, filterType: 'status' });
        }

        if (Array.isArray(filters.value.tags)) {
            filters.value.tags.forEach(tagId => {
                const tag = tags.value.find(t => t.id === tagId);
                if (tag) {
                    chips.push({
                        label: `Tag: ${tag.name}`,
                        value: tag.id,
                        filterType: 'tags'
                    });
                }
            });
        }

        return chips;
    });

    const removeFilterChip = chip => {
        const { filterType, value } = chip;
        if (filterType === 'status') {
            filters.value.status = null;
        }

        if (filterType === 'schoolId') {
            filters.value.schoolId = null;
            filters.value.tags = [];
            tags.value = [];
        }

        if (['labels', 'tags'].includes(filterType)) {
            filters.value[filterType] = filters.value[filterType].filter(id => id !== value);
        } else if (filterType === 'dateAfter' || filterType === 'dateBefore') {
            filters.value[filterType] = null;
        } else {
            filters.value[filterType] = null;
        }

        return filters.value;
    };

    return {
        filterChips,
        removeFilterChip
    };
} 
