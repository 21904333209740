<!-- Flow School Information Component -->
<template>
    <div class="school-info">
        <div class="school-info__loading" v-if="loading">
            <BaseLoading />
        </div>

        <div v-else class="school-info__content">
            <!-- School Statistics -->
            <FlowSchoolInfoDataItem
                v-for="item in dataItems"
                :key="item.label"
                :label="item.label"
                :value="item.getValue(flow)"
            />

            <!-- Download Report Button -->
            <ReportRequestDownloadButton
                reportType="CHATBOT_CONVERSATIONS"
                buttonText="Download"
                :reportOptions="{
                    chatbotFlowId: props.flowId,
                    cleanOutput: false,
                    skipFilters: true
                }"
            />
        </div>
    </div>
</template>

<script setup>
// Vue Core
import { ref, onMounted, computed } from 'vue';

// UI Components
import { BaseLoading } from '@edsights/ui-core';
import ReportRequestDownloadButton from '@/components-deprecated/ReportRequestDownloadButton';
import FlowSchoolInfoDataItem from './FlowSchoolInfoDataItem.vue';

// Services & Utilities
import * as ChatbotFlowApi from '@/api/chatbot-flow';
import Alerts from '@/lib/alerts';

// Props
const props = defineProps({
    flowId: {
        type: String,
        required: true
    }
});

// State
const loading = ref(false);
const flow = ref(null);

// Data Configuration
const dataItems = [
    {
        label: 'School Name',
        getValue: flow => flow?.schoolName
    },
    {
        label: 'Texts Created',
        getValue: flow => flow?.messagesCreated
    },
    {
        label: 'Texts Delivered',
        getValue: flow => `${flow?.messagesDeliveredPercentage}%`
    },
    {
        label: 'Nudged',
        getValue: flow => `${flow?.nudgeMessagesPercentage}%`
    },
    {
        label: 'Texts Responded',
        getValue: flow => `${flow?.studentsRespondedPercentage}%`
    },
    {
        label: 'Texts Errored',
        getValue: flow => `${flow?.twilioErrorPercentage}%`
    }
];

// Methods
const fetchFlowData = async () => {
    try {
        loading.value = true;
        flow.value = await ChatbotFlowApi.findById({ id: props.flowId });
    } catch (error) {
        Alerts.alert({
            type: 'error',
            message: "There was a problem getting the flow's information. Please try again."
        });
    } finally {
        loading.value = false;
    }
};

// Lifecycle Hooks
onMounted(fetchFlowData);
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

// Component Styles
.school-info {
    width: 100%;
    padding: 1.5rem;
    background-color: $white;
    border-radius: 4px;

    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &__content {
        display: flex;
        align-items: center;
        gap: 3rem;
        justify-content: space-between;
    }
}
</style>
