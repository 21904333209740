<template>
    <div class="tag-performance-card-container">
        <BaseCard>
            <template #content>
                <div class="performance-card-header">
                    <h2 class="performance-card__title">
                        Tag Performance
                        <BaseIcon
                            icon="questioncircle"
                            class="performance-card__title__helper-icon"
                            v-tooltip="'Your school\'s latest SVS for a particular group of tags'"
                        />
                    </h2>
                    <div class="performance-card-subtitle">
                        Explore satisfaction scores by tag group
                    </div>
                </div>
                <BaseDivider />
                <div class="tag-performance-card-content">
                    <BaseSelect
                        class="tag-performance-card-select"
                        v-model="tagGroup"
                        label="Explore Tag Groups"
                        :options="options"
                        :readOnly="loading"
                        @update:modelValue="loadTagSVSData"
                        @loadOptionsNextPage="onTagCategoriesNextPage"
                    />
                    <LoadingSpinner v-if="loading" />
                    <BaseCard v-else-if="showEmptyState" class="tag-performance-card-empty-state">
                        <template #content>
                            <EmptyState
                                icon="#icon-no-filter"
                                message="No data available"
                                sub-message="There are currently no responses for this tag group. Check back later as more feedback is collected."
                            />
                            <BaseButton
                                class="tag-performance-card-empty-state__button"
                                label="View All Tags"
                                @click="setAllTags"
                            />
                        </template>
                    </BaseCard>
                    <template v-else>
                        <div class="data-container">
                            <p class="data-container__title">
                                Tags in group
                                {{ thresholdText }}
                            </p>

                            <div v-for="(tagSVS, index) in tagsSVS" :key="index">
                                <ScoreProgress
                                    :showInline="true"
                                    :score="tagSVS.score"
                                    :comparedScore="comparedScore"
                                    :comparedScoreLabel="comparedScoreLabel"
                                    :scoreProgressLabel="tagSVS.tagName"
                                    :studentsCount="tagSVS.studentsCount"
                                    showScoreGradient
                                    showProgressBarColors
                                    :key="tagSVS.id"
                                />

                                <BaseDivider
                                    class="total-count-divider"
                                    align="center"
                                    v-if="additionalTagCount > 0 && index === 4"
                                >
                                    <b>{{ additionalTagCount }} additional tags</b>
                                </BaseDivider>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </BaseCard>
    </div>
</template>

<script setup>
import Alerts from '@/lib/alerts';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import EmptyState from '@/components-deprecated/global/EmptyState.vue';
import ScoreProgress from '../../components/ScoreProgress/ScoreProgress.vue';
import { BaseButton, BaseCard, BaseDivider, BaseIcon, BaseSelect } from '@edsights/ui-core';
import { computed, defineModel, defineProps, onBeforeMount, ref } from 'vue';
import { getStudentVoiceScoreTagPerformanceBySchoolIdAndTagCategoryIds } from '@/api/student-voice-score';
import { useStore } from 'vuex';
import CollectionManager from '@/services/collectionManager';
import v2CollectionManager from '@/services/v2CollectionManager';
import TagCategories from '@/services/tagCategories';

const props = defineProps({
    comparedScore: {
        type: Number,
        required: false
    },
    comparedScoreLabel: {
        type: String,
        required: false
    },
    score: {
        type: Number,
        required: false
    },
    enableSVSTagsFeatureFlag: {
        type: Boolean,
        default: false
    }
});

const store = useStore();

const options = ref([]);

const loading = ref(false);

const tagGroup = defineModel({ default: 'all' });

const tagsSVS = ref([]);

const totalCount = ref(0);

const tagCategories = props.enableSVSTagsFeatureFlag
    ? v2CollectionManager.create({
          listType: 'svsTagCategories',
          filters: {
              schoolId: store.state.user.schoolRef.id
          },
          errorHandlerOptions: {
              enableAlert: true,
              rethrow: false
          }
      })
    : CollectionManager.create({
          ModelClass: TagCategories,
          filters: { has_students: true },
          v2: true,
          slim: true
      });

const showEmptyState = computed(() => {
    return !loading.value && tagsSVS.value.length === 0;
});

const thresholdText = computed(() => {
    return totalCount && totalCount.value > 10 ? '(Minimum of 5 responses)' : '';
});

const additionalTagCount = computed(() => {
    if (totalCount.value <= 10) {
        return 0;
    }
    return totalCount.value - 10;
});

async function loadTagSVSData() {
    try {
        loading.value = true;

        const {
            count,
            results
        } = await getStudentVoiceScoreTagPerformanceBySchoolIdAndTagCategoryIds({
            schoolId: store.state.user.schoolRef.id,
            tagCategoryIds: tagGroup.value && tagGroup.value !== 'all' ? [tagGroup.value] : [],
            errorHandlerOptions: {
                rethrow: true,
                enableAlert: false
            }
        });

        tagsSVS.value = results.map(tag => {
            return {
                ...tag,
                studentsCount:
                    (tag.detractorCount ?? 0) + (tag.passiveCount ?? 0) + (tag.promoterCount ?? 0)
            };
        });

        totalCount.value = count;
    } catch (error) {
        console.log(error);
        Alerts.alert({
            type: 'error',
            message: 'Could not load tag data.'
        });
        tagsSVS.value = [];
    } finally {
        loading.value = false;
    }
}

async function loadTagFilterData(shouldRefresh = true) {
    if (shouldRefresh) {
        await tagCategories.refresh();
    }

    options.value = tagCategories?.list ?? [];

    options.value = options.value
        .filter(item => item !== null)
        .map(item => {
            return {
                value: item?.id,
                label: item?.name,
                tagsRef: item?.tagsRef
            };
        });

    options.value.unshift({
        value: 'all',
        label: 'All Tags',
        tagsRef: []
    });
}

async function setAllTags() {
    tagGroup.value = 'all';
    await loadTagSVSData();
}

async function onTagCategoriesNextPage() {
    if (tagCategories.pagination.next != null) {
        await tagCategories.addNextPage();
        await loadTagFilterData(false);
    }
}

onBeforeMount(async () => {
    loading.value = true;

    await loadTagFilterData();

    await loadTagSVSData();
    loading.value = false;
});
</script>

<style scoped lang="scss" src="./tag-performance-card.scss"></style>
