<template>
    <div class="container">
        <ButtonBack />

        <div v-if="isLoading">
            <LoadingSpinner />
        </div>
        <div v-else>
            <div class="key-info-container">
                <h1 class="h2">Key Account Information</h1>
                <p>
                    Below is the key account information. This page is "view only" so if you need to
                    make edits, reach out to your EdSights point of contact.
                </p>

                <!-- School Information -->
                <section class="section-container">
                    <h2 class="h3">School Information</h2>

                    <div class="row-container">
                        <div>
                            <p class="description">School Name</p>

                            <template v-if="isStaff">
                                <Input
                                    v-model.trim="keyInfo.name"
                                    :placeholder="'Full school name'"
                                />
                                <FieldError :errors="errors" name="schoolName" />
                                <p class="school-name-info" v-if="schoolNameInfo">
                                    {{ schoolNameInfo }}
                                </p>
                            </template>
                            <template v-else>{{ keyInfo.name }}</template>
                        </div>
                    </div>
                    <div class="row-container">
                        <div>
                            <p class="description">School Nickname</p>

                            <template v-if="isStaff">
                                <Input
                                    v-model.trim="keyInfo._Nickname"
                                    :placeholder="'School nickname'"
                                />
                                <FieldError :errors="errors" name="schoolNickname" />
                            </template>
                            <template v-else>{{ keyInfo._Nickname }}</template>
                        </div>
                    </div>

                    <div class="row-container">
                        <div>
                            <p class="description">School Website</p>

                            <template v-if="isStaff">
                                <Input
                                    v-model.trim="keyInfo.website"
                                    :placeholder="'School website'"
                                />
                                <FieldError :errors="errors" name="schoolWebsite" />
                            </template>
                            <template v-else>{{ keyInfo.website }}</template>
                        </div>
                    </div>
                    <div class="row-container">
                        <div>
                            <p class="description">Assigned number</p>
                            <template
                                v-if="
                                    keyInfo.schoolPhoneNumber !== null &&
                                        keyInfo.schoolPhoneNumber !== ''
                                "
                            >
                                <p class="value">
                                    {{ formattedPhone }}
                                </p>
                            </template>
                            <template v-else
                                ><p class="value">
                                    There is no phone number associated with this account.
                                </p>
                            </template>
                        </div>
                    </div>
                </section>
                <div class="divider" />

                <!-- Mascot Information -->
                <section class="section-container">
                    <h2 class="h3">Mascot Information</h2>

                    <div class="row-container">
                        <div>
                            <p class="description">Mascot Name</p>

                            <template v-if="isStaff">
                                <Input
                                    v-model.trim="keyInfo.mascotName"
                                    :placeholder="'Mascot name'"
                                />
                                <FieldError :errors="errors" name="mascotName" />
                            </template>
                            <template v-else>{{ keyInfo.mascotName }}</template>
                        </div>
                    </div>

                    <div class="row-container">
                        <div>
                            <p class="description">Heart Emoji</p>

                            <Input
                                v-if="isStaff"
                                v-model.trim="keyInfo.heartEmoji"
                                :placeholder="'Heart Emoji'"
                            />
                            <template v-else>{{ keyInfo.heartEmoji }}</template>
                        </div>
                    </div>

                    <div class="row-container">
                        <div>
                            <p class="description">Mascot Emoji</p>

                            <Input
                                v-if="isStaff"
                                v-model.trim="keyInfo.mascotEmoji"
                                :placeholder="'Mascot Emoji'"
                            />
                            <template v-else>{{ keyInfo.mascotEmoji }}</template>
                        </div>
                    </div>

                    <div class="row-container">
                        <div>
                            <p class="description">Mascot Pronouns</p>

                            <Input
                                v-if="isStaff"
                                v-model.trim="keyInfo.mascotPronouns"
                                :placeholder="'Mascot Pronouns'"
                            />
                            <template v-else>{{ keyInfo.mascotPronouns }}</template>
                        </div>
                    </div>
                </section>
                <div class="divider" />

                <!-- EdSights Points of Contact -->
                <section class="section-container">
                    <h2 class="h3">EdSights Points of Contact</h2>
                    <template v-if="isStaff">
                        <div class="row-container">
                            <div>
                                <BaseSelect
                                    label="Point of Contact 1 Name"
                                    :modelValue="selectedPointOfContactOne.id"
                                    :options="pointsOfContact"
                                    optionLabel="name"
                                    optionValue="id"
                                    @update:modelValue="pointOfContactOneChangeHandler"
                                />
                            </div>

                            <div>
                                <p class="description">Point of Contact 2 Name</p>
                                <BaseSelect
                                    :modelValue="selectedPointOfContactTwo.id"
                                    :options="pointsOfContact"
                                    optionLabel="name"
                                    optionValue="id"
                                    @update:modelValue="pointOfContactTwoChangeHandler"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="row-container">
                            <div>
                                <p class="description">Point of Contact 1 Name</p>

                                <label>{{
                                    `${selectedPointOfContactOne.firstName} ${selectedPointOfContactOne.lastName}`
                                }}</label>
                            </div>

                            <div>
                                <p class="description">Point of Contact 1 Email</p>

                                <label>{{ selectedPointOfContactOne.email }}</label>
                            </div>
                        </div>

                        <div class="row-container">
                            <div>
                                <p class="description">Point of Contact 2 Name</p>

                                <label>{{
                                    `${selectedPointOfContactTwo.firstName} ${selectedPointOfContactTwo.lastName}`
                                }}</label>
                            </div>

                            <div>
                                <p class="description">Point of Contact 2 Email</p>

                                <label>{{ selectedPointOfContactTwo.email }}</label>
                            </div>
                        </div>
                    </template>
                </section>

                <div class="divider" />

                <!-- Student Point of Contact -->
                <section class="section-container">
                    <h2 class="h3">Student Point of Contact</h2>

                    <div class="row-container">
                        <div>
                            <p class="description">Student Contact Name</p>

                            <Input
                                v-if="isStaff"
                                v-model.trim="keyInfo.studentPointOfContactName"
                                :placeholder="'Student contact full name'"
                            />
                            <template v-else>{{ keyInfo.studentPointOfContactName }}</template>
                        </div>

                        <div>
                            <p class="description">Student Contact Email</p>

                            <Input
                                v-if="isStaff"
                                v-model.trim="keyInfo.studentPointOfContactEmail"
                                :placeholder="'Student contact email'"
                            />
                            <template v-else>{{ keyInfo.studentPointOfContactEmail }}</template>
                        </div>
                    </div>
                </section>
                <div class="divider" />

                <!-- Summer Melt Point of Contact -->
                <section class="section-container">
                    <h2 class="h3">Summer Melt Point of Contact</h2>

                    <div class="row-container">
                        <div>
                            <p class="description">Summer Melt Contact Name</p>

                            <Input
                                v-if="isStaff"
                                v-model.trim="keyInfo.meltPointOfContactName"
                                :placeholder="'Melt Point contact full name'"
                            />
                            <template v-else>{{ keyInfo.meltPointOfContactName }}</template>
                        </div>

                        <div>
                            <p class="description">Summer Melt Contact Email</p>

                            <Input
                                v-if="isStaff"
                                v-model.trim="keyInfo.meltPointOfContactEmail"
                                :placeholder="'Melt Point contact email'"
                            />
                            <template v-else>{{ keyInfo.meltPointOfContactEmail }}</template>
                        </div>
                    </div>
                </section>
                <div class="divider" />

                <!-- IPEDS Attributes -->
                <section class="section-container">
                    <h2 class="h3">IPEDS Attributes</h2>
                    <div
                        v-for="categoryPair in institutionTypeCategoriesFormattedForTemplate"
                        :key="categoryPair[0].id"
                        class="row-container"
                    >
                        <div v-for="category in categoryPair" :key="category.id">
                            <p class="description">{{ category.name }}</p>
                            <Dropdown
                                v-if="isStaff"
                                :options="category.institutionTypes"
                                @option-clicked="
                                    value => institutionTypeChangeHandler(category.id, value)
                                "
                                :label="category.selectedLabel"
                                :useStyledLabel="false"
                            />
                            <template v-else>{{ category.selectedLabel }}</template>
                        </div>
                    </div>
                </section>
            </div>

            <div class="button-container" v-if="isStaff">
                <LoadingSpinnerButton
                    class="save-btn"
                    @click="() => saveKeyInformation({ confirm: true })"
                    text="Save"
                    :loading="isLoading"
                />
            </div>
        </div>
        <Modal
            v-if="showConfirmationModal"
            title="Confirm Changes"
            size="medium"
            primaryButtonTitle="Save"
            @secondary-button-clicked="showConfirmationModal = false"
            @primary-button-clicked="() => saveKeyInformation({ confirm: false })"
        >
            <div>
                <div v-if="isNameChanged" v-html="isNameChangedConfirmationMessage" />
            </div>
        </Modal>
    </div>
</template>

<script>
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import TransitionDropdown from '@/components-deprecated/inputs/TransitionDropdown';
import Input from '@/components-deprecated/Input';
import FieldError from '@/components-deprecated/FieldError';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import Modal from '@/components-deprecated/global/Modal';
import * as SchoolApi from '@/api/school';
import * as UserApi from '@/api/user';
import * as InstitutionTypeCategoryApi from '@/api/institution-type-category';
import { apiErrorHandler } from '@/services/api';
import { mapState } from 'vuex';
import { formatSchoolName } from '@edsights/school-helpers';
import Dropdown from '@/components-deprecated/global/Dropdown.vue';
import ButtonBack from '@/components-deprecated/ButtonBack.vue';
import { BaseSelect } from '@edsights/ui-core';

export default {
    name: 'KeyInformation',
    components: {
        ButtonBack,
        Dropdown,
        LoadingSpinner,
        Input,
        FieldError,
        TransitionDropdown,
        LoadingSpinnerButton,
        Modal,
        BaseSelect
    },
    data() {
        return {
            isLoading: true,
            keyInfo: {},
            originalKeyInfo: {},
            errors: [],
            pointsOfContact: [],
            selectedPointOfContactOne: {},
            selectedPointOfContactTwo: {},
            institutionTypeCategories: [],
            selectedInstitutionTypes: [],
            showConfirmationModal: false
        };
    },
    async created() {
        this.isLoading = true;
        await this.refresh();
        this.isLoading = false;
    },
    computed: {
        ...mapState(['user']),
        formattedSchoolName() {
            const name = this.keyInfo.name;

            if (typeof name !== 'string') {
                return null;
            }

            return formatSchoolName(name);
        },
        isStaff() {
            return this.$store.state.user.isStaff;
        },
        schoolNameInfo() {
            if (typeof this.formattedSchoolName !== 'string') {
                return null;
            }

            if (this.keyInfo.name !== this.formattedSchoolName) {
                return `Note: The "{{school.name}}" Chatbot Flow template binding and the title of the Web Chatbot (if applicable) will be formatted as "${this.formattedSchoolName}".`;
            }

            return null;
        },
        isNameChanged() {
            return (
                typeof this.originalKeyInfo === 'object' &&
                typeof this.originalKeyInfo.name === 'string' &&
                typeof this.keyInfo === 'object' &&
                typeof this.keyInfo.name === 'string' &&
                this.originalKeyInfo.name !== this.keyInfo.name
            );
        },
        isNameChangedConfirmationMessage() {
            if (this.isNameChanged) {
                return `
                    Are you sure you want to change the school's name from <b>${this.originalKeyInfo.name}</b> to <b>${this.keyInfo.name}</b>?
                    <br />
                    <br />
                    The "{{school.name}}" Chatbot Flow template binding and the title of the Web Chatbot (if applicable) will be formatted as <b>${this.formattedSchoolName}</b>.
                `;
            }

            return '';
        },
        institutionTypeCategoriesFormattedForTemplate() {
            if (
                !Array.isArray(this.institutionTypeCategories) ||
                !this.institutionTypeCategories.length
            ) {
                return [];
            }

            // ensure the categories and their types are sorted,
            // and formatted for use with the template + dropdown
            const sorted = [...this.institutionTypeCategories]
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map(cat => ({
                    ...cat,
                    institutionTypes: cat.institutionTypes
                        .sort((a, b) => a.displayOrder - b.displayOrder)
                        .map(type => ({ label: type.name, value: type.id })),
                    selectedLabel: (
                        this.selectedInstitutionTypes.find(type => type.categoryId === cat.id) || {}
                    ).name
                }));

            // divide categories into pairs to match the template structure
            let pairs = [];
            for (let i = 0; i < sorted.length; i += 2) {
                pairs.push(sorted.slice(i, i + 2));
            }
            return pairs;
        },
        formattedPhone() {
            if (!this.keyInfo.schoolPhoneNumber) {
                return '';
            }
            const cleaned = this.keyInfo.schoolPhoneNumber.replace(/\D/g, '');
            if (cleaned.length === 11 && cleaned[0] === '1') {
                const countryCode = cleaned[0];
                const areaCode = cleaned.slice(1, 4);
                const firstThree = cleaned.slice(4, 7);
                const lastFour = cleaned.slice(7);
                return `+${countryCode} (${areaCode}) ${firstThree}-${lastFour}`;
            } else if (cleaned.length === 10) {
                const areaCode = cleaned.slice(0, 3);
                const firstThree = cleaned.slice(3, 6);
                const lastFour = cleaned.slice(6);
                return `(${areaCode}) ${firstThree}-${lastFour}`;
            }
            return this.keyInfo.schoolPhoneNumber;
        }
    },
    methods: {
        pointOfContactOneChangeHandler(id) {
            this.selectedPointOfContactOne = this.pointsOfContact.find(
                pointOfContact => pointOfContact.id === id
            );
        },
        pointOfContactTwoChangeHandler(id) {
            this.selectedPointOfContactTwo = this.pointsOfContact.find(
                pointOfContact => pointOfContact.id === id
            );
        },
        institutionTypeChangeHandler(categoryId, typeId) {
            const category = this.institutionTypeCategories.find(cat => cat.id === categoryId);
            const type = category.institutionTypes.find(type => type.id === typeId);

            // this is a "single select".
            // filter out any types of the same category, and add the type.
            // (only 1 type per category is allowed).
            this.selectedInstitutionTypes = [
                ...this.selectedInstitutionTypes.filter(
                    selectedType => selectedType.categoryId !== categoryId
                ),
                {
                    categoryId,
                    ...type
                }
            ];
        },
        checkForErrors() {
            const errors = [];
            if (!this.keyInfo.name) {
                errors.push({
                    field: 'schoolName',
                    detail: 'School name required.'
                });
            }
            if (!this.keyInfo._Nickname) {
                errors.push({
                    field: 'schoolNickname',
                    detail: 'School nickname required.'
                });
            }
            if (!this.keyInfo.mascotName) {
                errors.push({
                    field: 'mascotName',
                    detail: 'Mascot name required.'
                });
            }
            this.errors = errors;
            return errors.length;
        },
        async refresh() {
            // staff have the ability to change points of contact.
            // load the options:
            if (this.isStaff) {
                this.pointsOfContact = await UserApi.findPointsOfContact();
            }
            const bustSchoolCache = true;
            const school = await SchoolApi.findById(this.user.school, bustSchoolCache);
            // spread into new objects to prevent mutation
            this.keyInfo = { ...school };
            this.originalKeyInfo = { ...school };

            this.selectedPointOfContactOne = this.keyInfo.pointsOfContact[0] || {};
            this.selectedPointOfContactTwo = this.keyInfo.pointsOfContact[1] || {};

            this.institutionTypeCategories = await InstitutionTypeCategoryApi.listInstitutionTypeCategories(
                {
                    errorHandlerOptions: {
                        rethrow: false,
                        enableAlert: true
                    }
                }
            );
            this.selectedInstitutionTypes = this.keyInfo.institutionTypes || [];
        },
        async saveKeyInformation({ confirm = true } = {}) {
            if (this.checkForErrors()) {
                return;
            }

            if (confirm === true && this.isNameChanged) {
                this.showConfirmationModal = true;
                return;
            } else {
                this.showConfirmationModal = false;
            }

            this.isLoading = true;
            try {
                await SchoolApi.updateKeyInformation(this.user.school, {
                    school: {
                        name: this.keyInfo.name,
                        nickname: this.keyInfo._Nickname,
                        mascotName: this.keyInfo.mascotName,
                        studentPointOfContactName: this.keyInfo.studentPointOfContactName,
                        studentPointOfContactEmail: this.keyInfo.studentPointOfContactEmail,
                        meltPointOfContactName: this.keyInfo.meltPointOfContactName,
                        meltPointOfContactEmail: this.keyInfo.meltPointOfContactEmail,
                        heartEmoji: this.keyInfo.heartEmoji,
                        mascotEmoji: this.keyInfo.mascotEmoji,
                        mascotPronouns: this.keyInfo.mascotPronouns,
                        website: this.keyInfo.website
                    },
                    pointsOfContact: [
                        this.selectedPointOfContactOne,
                        this.selectedPointOfContactTwo
                    ],
                    institutionTypes: this.selectedInstitutionTypes
                });
                await this.refresh();
            } catch (error) {
                apiErrorHandler({ apiName: 'Save Key Information error' });
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@/styles/mixins/buttons';

.container {
    // large padding is to accommodate dropdowns positioned towards the bottom of the container
    padding-bottom: 30rem;
}

.ui-core-base-select {
    width: 35em;
}

:deep(.ui-core-base-select label) {
    font-weight: bold;
    font-family: 'Poppins-SemiBold';
}

.search-container {
    margin: 20px 0 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
}

.school-filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    * {
        margin: 0 0.5rem;
    }
    label {
        white-space: nowrap;
        font-size: 14px;
        color: black;
        font-weight: bold;
    }
    .dd__school {
        width: 30rem;
        height: 40px;
        display: flex;
        align-items: center;
    }
}

.section-container {
    margin-top: 25px;
    margin-left: 25px;
}

.row-container {
    display: flex;
    justify-content: space-between;
    & > * {
        margin-top: 20px;
        width: 40%;
        max-width: 40%;

        input {
            margin: 0;
        }
    }
}

:deep(.input-container) {
    .input-group {
        > .input-label {
            max-width: 35rem;
        }
    }
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #c3c4d0;
    margin-top: 30px;
}

.description {
    color: black;
    font-size: 12px;
    font-family: 'Poppins-SemiBold';
}

.value {
    font-size: 1.1em;
}

.input {
    width: 35rem;
    margin: 1rem 0;
}

.key-info-container {
    display: flex;
    flex-direction: column;
    padding: 20px 5px;

    .h3 {
        color: black;
        font-size: initial;
    }
}

:deep(.dropdown) {
    .dropdown-input-container {
        width: 100%;
        align-items: center;
        border: 1px solid $secondary-gray;
        border-radius: 5px;

        > .search,
        .selected-items {
            height: 40px;
            border: none;
            background: transparent;

            height: 32px;

            min-width: unset;
            font-size: 14px;
        }
        &.disabled {
            border: 1px solid gray;
        }
    }
}

.save-btn {
    @include base-button();
}

.button-container {
    display: flex;
    justify-content: flex-end;
}
</style>
